import BaseComponent from "../../BaseComponent";
import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from "intl-tel-input";

export default class PhoneInput extends BaseComponent {
    private iti: intlTelInput.Plugin;

    public init(): void {
        const $phoneDefault = this.$component.attr('data-phone-default');

        this.iti = intlTelInput(this.$component[0], {
            nationalMode: false,
            formatOnDisplay: false,
            initialCountry: $phoneDefault ? this.getCountryCodeFromLanguage($phoneDefault).toLowerCase() : 'cz',
            utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@18.2.1/build/js/utils.js',
        });

        this.$component.on('blur', () => {
            this.$component.val(this.iti.getNumber());
        });
    }

    private getCountryCodeFromLanguage(languageCode: string): string | undefined {
        return {
            cs: 'CZ',
            sk: 'SK',
            en: 'GB',
            hu: 'HU',
            pl: 'PL',
            th: 'TH',
            ro: 'RO',
            bg: 'BG',
            de: 'DE',
        }[languageCode.toLocaleLowerCase()] ?? languageCode;
    }
}
