import IExtension from "./IExtension";
import {Naja} from "naja";

/**
 * Classic extension is attached to set of elements and should be initialized for redrawn snippets
 */
export default abstract class ClassicExtension implements IExtension {
    public initialize(naja: Naja): void {
        naja.addEventListener('init', () => this.init($('body')));
        naja.snippetHandler.addEventListener('afterUpdate', (e) => this.init($(<HTMLElement>e.detail.snippet)));
    }

    protected abstract init($root: JQuery): void;
}
