import BaseComponent from "../../BaseComponent";

/**
 * Mark stars with GOLD_CLASS until find checked radio
 * expected structure:
 *  parent div ............ some star container
 *  .star-rating .......... one star element contains label and input
 *  label ................. symbol of star
 *  input[type="radio"] ... hidden radio input
 */
export default class StarsRating extends BaseComponent {

    private static readonly GOLD_CLASS = 'text-gold';

    init(): void {
        this.$component.find('.star-rating input[type="radio"]').each((i, input) => {
            const $input = $(input);

            $input.on('change', e => {
                this.fillStars()
            });
        });

        this.$component.find('input[value="no_rating"]').on('change', (e) => {
            if ($(e.currentTarget).is(':checked')) {
                this.clear();
            }
        });

        this.fillStars();
    }

    private fillStars(): void {
        let selectedStarIndex = -1;
        this.$component.find('.star-rating').each((starIndex, oneOfActiveStar) => {
            if ($(oneOfActiveStar).find('input[type="radio"]:checked').length) {
                selectedStarIndex = starIndex;
                return false;
            }
        }).each( (starIndex, oneOfActiveStar) => {
            const $label = $(oneOfActiveStar).find('label');
            if (starIndex <= selectedStarIndex) {
                $label.addClass(StarsRating.GOLD_CLASS);
            } else {
                $label.removeClass(StarsRating.GOLD_CLASS);
            }
        });
    }


    private clear(): void {
        this.$component.find('.'+StarsRating.GOLD_CLASS).each( (i,el) => {
            $(el).removeClass(StarsRating.GOLD_CLASS)
        });
    }

}
