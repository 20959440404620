import BaseComponent from "../../BaseComponent";

export default class Autocomplete extends BaseComponent {
    public $form: JQuery<HTMLFormElement>;
    public $ulField: JQuery<HTMLUListElement>;
    public $inputField: JQuery<HTMLInputElement>;

    public abortController: AbortController;
    public abortSignal: AbortSignal;

    public init (): void {
        if (this.$component.find('[data-autocomplete]').length > 0) {
            this.$form = <JQuery<HTMLFormElement>> this.$component.closest('form');
            this.$ulField = this.createUlField();

            this.$inputField = <JQuery<HTMLInputElement>> this.$component.find('[data-autocomplete]');
            this.$inputField.on('input', e => {
                // Abort previous request
                if (this.abortController && this.abortSignal && !this.abortSignal.aborted) {
                    this.abortController.abort();
                }
                this.changeAutoComplete(<HTMLInputElement>e.currentTarget);
            });

            this.$component.find('[data-autocomplete]').after(this.$ulField);

            // add user project
            this.$form.find('button[name="add-to-project"]').on('click', () => {
                if (this.$form.find('.project-roles-error').length > 0) {
                    this.$form.find('.project-roles-error').removeClass('show');
                }

                this.addUserProject();
                this.bindRemoveEvents();
                return false;
            });

            // bind access events
            this.bindUserAccessEvents();

            // bind remove projects events
            this.bindRemoveEvents();
        }
    }

    public addUserProject (): boolean {
        const projectsRolesContainer = this.$form.find('.projects-roles');
        const projectName = this.$inputField.attr('data-item-name');
        const projectId = this.$inputField.attr('data-item-id');
        const projectRole = this.$form.find('select[name="add-to-project-select"]').val();

        // check if is valid project
        if (projectId && projectRole && projectName && projectsRolesContainer.length > 0 && projectsRolesContainer.find('input[name="project[' + projectId + ']"]').length === 0) {
            const userProjectTag = $('<div class="small-12 label project-role-' + projectRole + '"><div class="row"><div class="small-6"><input type="hidden" name="project[' + projectId + '][role]" value="' + projectRole + '" /><div class="project-name">' + projectName + ' (#' + projectId + ')</div></div><div class="small-5"><input type="text" name="project[' + projectId + '][nick]" value="" placeholder="nick" /></div><div class="small-1 project-role-remove" data-id="' + projectId + '">✖</div></div></div>');
            projectsRolesContainer.append(userProjectTag);
        } else if (projectsRolesContainer.find('input[name="project[' + projectId + ']"]').length > 0) {
            if (this.$form.find('.project-roles-error').length > 0) {
                this.$form.find('.project-roles-error').addClass('show');
            }
        }

        return false;
    }

    public bindRemoveEvents (): void {
        const projectsRolesContainer = this.$form.find('.projects-roles');
        if (projectsRolesContainer.length > 0) {
            projectsRolesContainer.find('.project-role-remove').each((index, element) => {
                $(element).on('click', e => {
                    $(e.target).closest('.label').remove();
                });
            });
        }
    }

    public bindUserAccessEvents (): void {
        // add user project
        this.$form.find('.project-roles .toggle-show').off('click').on('click', (e) => {
            const btnElement = $(e.target);
            const parentProjectRow = $(btnElement).closest('.project-roles');
            if (parentProjectRow) {
                $(parentProjectRow).find('.access-list').addClass('active');
                $(parentProjectRow).find('.toggle-show').removeClass('active');
                $(parentProjectRow).find('.toggle-hide').addClass('active');
            }
        });
        this.$form.find('.project-roles .toggle-hide').off('click').on('click', (e) => {
            const btnElement = $(e.target);
            const parentProjectRow = $(btnElement).closest('.project-roles');
            if (parentProjectRow) {
                $(parentProjectRow).find('.access-list').removeClass('active');
                $(parentProjectRow).find('.toggle-show').addClass('active');
                $(parentProjectRow).find('.toggle-hide').removeClass('active');
            }
        });
        this.$form.find('.project-pageaccess-remove').off('click').on('click', (e) => {
            const btnElement = $(e.target);
            if ($(btnElement).closest('.access-list').find('div > .row').length > 1) {
                $(btnElement).closest('.row').remove();
            } else {
                $(btnElement).closest('.row').find('input[type="text"]').val('');
            }
        });
        this.$form.find('.project-pageaccess-add').off('click').on('click', (e) => {
            const btnElement = $(e.target);
            const permRowClone = $(btnElement).closest('.row').clone();
            $(permRowClone).find('input').val('');
            $(btnElement).closest('.row').after(permRowClone);

            this.bindUserAccessEvents();
        });
    }

    public closeList (): void {
        this.$ulField.empty();
    }

    public createUlField (): JQuery<HTMLUListElement> {
        const $ul = <JQuery<HTMLUListElement>> $('<ul>');
        $ul.attr('class', 'autocomplete-list');
        return $ul;
    }

    public createLiItem (listItem: {id, name}): HTMLLIElement {
        const $li = document.createElement('li');
        $li.setAttribute('class', 'autocomplete-item');
        $li.setAttribute('data-item-id', listItem.id);
        $li.textContent = listItem.name + ' (#' + listItem.id + ')';
        $li.setAttribute('data-item-name', listItem.name);
        $li.addEventListener('click', e => {
            const dst = e.target as HTMLLIElement;
            this.onSelectItem(dst);
        });
        return $li;
    }

    public changeAutoComplete (target: HTMLInputElement): void {
        const searchterm = target.value;

        this.$ulField.empty();

        if (searchterm.length) {
            const that = this;

            // check abort signal
            this.abortController = new AbortController();
            this.abortSignal = this.abortController.signal;

            let fetchUrl = this.$inputField.attr('data-autocomplete-url');
            fetchUrl += '&term=' + searchterm;

            // Fetch the data and render the datalist element
            fetch(fetchUrl, {
                method: 'get',
                signal: this.abortSignal
            }).then(function (response) {
                if (response.ok) {
                    return response.json();
                }
                throw response;
            }).then(function (data) {
                data.forEach(value => {
                    that.addItem(value);
                });
            }).catch(function (error) {
                if (error.name === 'AbortError') {
                    console.log(error);
                } else {
                    console.warn(error);
                }
            });
        }
    }

    public addItem (item: {id, name}): void {
        this.$ulField.append(this.createLiItem(item));
    }

    public onSelectItem (liElement: HTMLLIElement): void {
        const inputText = liElement.getAttribute('data-item-name') + ' (#' + liElement.getAttribute('data-item-id') + ')';
        this.$inputField.val(inputText);
        // fill selected data
        this.$inputField.attr('data-item-id', liElement.getAttribute('data-item-id'));
        this.$inputField.attr('data-item-name', liElement.getAttribute('data-item-name'));
        // close list
        this.closeList();
    }
}
