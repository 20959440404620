import BaseComponent from "../../BaseComponent";

export default class EntryTags extends BaseComponent {
    public init(): void {
        this.$component.find('input[type="search"]')
            .on('input', e => this.searchTag( String($(e.currentTarget).val()) ))
            .trigger('input');
    }

    private searchTag(searchQuery: string): void {
        searchQuery = this.normalizeString(searchQuery);
        this.$component.find('[data-available-tag]').each( (i, tag) => {
            const $tag = $(tag);
            if (!this.normalizeString($tag.children('a').text()).match(searchQuery)){
                $tag.hide();
            } else {
                $tag.show();
            }
        });
    }

    private normalizeString(string: string): string {
        const res = string.toLowerCase().trim();
        if (typeof String.prototype.normalize === "function") {
            // remove diacritics
            return res.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        } else { // Poor browser cannot normalize
            return res;
        }

    }
}
