import BaseComponent from "../../BaseComponent";

export default class RewardsForm extends BaseComponent {
    public init(): void {
        this.minIsEqualOrLessThanMax();
    }

    private minIsEqualOrLessThanMax(): void {
        const $min = this.$component.find('input[name="rewards[min_diaries_for_reward]"]');
        const $max = this.$component.find('input[name="rewards[max_diaries_for_reward]"]');

        $min.on('change', e => {
            const min = parseInt(<string>$min.val());
            const max = parseInt(<string>$max.val());
            if (min < 0) {
                $min.val(Math.max(min, 0));
            }
            if (max < min) {
                $max.val(Math.max(min, 0));
            }
        });

        $max.on('change', e => {
            const min = parseInt(<string>$min.val());
            const max = parseInt(<string>$max.val());

            if (max < 0) {
                $max.val(Math.max(max, 0));
            }
            if (max < min) {
                $min.val(Math.max(max, 0));
            }
        });
    }
}