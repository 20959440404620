// TODO možná extenze, a nevím k čemu to patří
import BaseComponent from "../../BaseComponent";

export default class GridActionOnChange extends BaseComponent {

    private action: string;
    private $actionSelect: JQuery<HTMLSelectElement>;
    private $submitBtn: JQuery<HTMLButtonElement>;

	public init(): void {
        this.action = this.$component.data('action');

        const $form = this.$component.find('form:first-of-type');
        this.$actionSelect = <JQuery<HTMLSelectElement>>$form.find('select[name="group_action[group_action]"]');
        this.$submitBtn = <JQuery<HTMLButtonElement>> $form.find('button[name="group_action[submit]"]');

        this.$submitBtn.data('confirmed', true);

		this.$component.find('table').on('click', 'td.col-checkbox input[type="checkbox"]', () => this.handleCheckboxChange());
		this.$component.find('table').on('click', 'th input[name="grid-toggle-all"]', () => this.handleCheckboxChange())

	}

	private handleCheckboxChange(): void {
	    setTimeout(() => {
            this.$actionSelect.val(this.action);
            this.$actionSelect.trigger('click');
            this.$actionSelect.trigger('change');
            this.$submitBtn.off('click');
            this.$submitBtn.trigger('click');
        }, 1);
    }
}
