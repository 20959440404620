import BaseComponent from '../../BaseComponent';
import TemplateLoader from '../../TemplateLoader';
import ClipboardJS from 'clipboard';
import iziToast from 'izitoast';

export default class VideoCallForm extends BaseComponent {
    private static readonly SELECTOR_VIDEO_RESPONDENTS = '[data-video-respondents]';
    private static readonly SELECTOR_VIDEO_RESPONDENT = '[data-video-respondent]';
    private static readonly SELECTOR_ADD_RESPONDENT = '[data-add-video-respondent]';
    private static readonly SELECTOR_REMOVE_RESPONDENT = '[data-remove-video-respondent]';

    private respondentTypes;

    public init (): void {
        TemplateLoader.init('VideoCallForm');

        this.registerListeners();
        this.context.naja.snippetHandler.addEventListener('afterUpdate', e => {
            this.registerListeners();
        });
        this.context.naja.addEventListener('complete', e => {
            this.updateAddBtn();
        });

        this.respondentTypes = this.$component.find('input[name*="respondent_types"]').data('respondent-types');
    }

    private addVideoRespondent (): void {
        const respondentsContent = this.$component.find(VideoCallForm.SELECTOR_VIDEO_RESPONDENTS);
        const videoId = $(respondentsContent).data('video-id');
        const respondentIndex = $(respondentsContent).closest(VideoCallForm.SELECTOR_VIDEO_RESPONDENTS).find(VideoCallForm.SELECTOR_VIDEO_RESPONDENT).length;

        // load template
        const template = TemplateLoader.loadTemplate('videoCallRespondent', {
            videoId: videoId,
            respondentIndex: respondentIndex
        });

        // add respondent types to select
        if (this.respondentTypes) {
            for (const type in this.respondentTypes) {
                $(template.content).find('.respondent-types-select').append('<option value="' + type + '">' + this.respondentTypes[type] + '</option>');
            }
        }

        respondentsContent.append(template.content);

        this.registerListeners();
    }

    private removeVideoRespondent (button: HTMLElement): void {
        const nearestRow = $(button).closest(VideoCallForm.SELECTOR_VIDEO_RESPONDENT);
        nearestRow.remove();
    }

    private registerListeners () {
        this.$component.find(VideoCallForm.SELECTOR_ADD_RESPONDENT)
            .not('.event-bound')
            .on('click', () => {
                this.$component.find('#respondents-header').removeClass('d-none');
                this.addVideoRespondent();
            }).addClass('event-bound');

        this.$component.find(VideoCallForm.SELECTOR_REMOVE_RESPONDENT)
            .not('.event-bound')
            .on('click', (e) => {
                this.removeVideoRespondent(e.currentTarget);
            }).addClass('event-bound');

        this.$component.find('[data-clipboard-target]').each((i, el) => {
            const clipboard = new ClipboardJS(el);
            clipboard.on('success', () => {
                iziToast.info({ message: $(el).data('success'), timeout: 1500 });
            });
        });
    }

    private updateAddBtn () {
        // enable/disable add video btn
        if (this.$component.parent().length > 0 && !this.$component.is(':empty')) {
            if (!this.$component.parent().parent().find('button[name="videos[add]"]').hasClass('active')) {
                this.$component.parent().parent().find('button[name="videos[add]"]').addClass('active');
            }
            this.$component.parent().parent().find('button[name="videos[add]"]').prop('disabled', true);
        } else {
            if ($('#frm-discussionForm-form button[name="videos[add]"]').hasClass('active')) {
                $('#frm-discussionForm-form button[name="videos[add]"]').removeClass('active');
            }
            $('#frm-discussionForm-form button[name="videos[add]"]').prop('disabled', false);
        }
    }
}
