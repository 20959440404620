import ClassicExtension from "./ClassicExtension";

/**
 * Warn on unsaved changes
 *  - warning is enabled after onchange event on any form input inside <tag data-warn-on-unsaved-changes /> container
 *  - warning can be force canceled with presence of <tag data-cancel-warning-on-unsaved-changes /> inside the container
 */
export default class WarnOnUnsavedChangesExtension extends ClassicExtension  {

    private warnMessage = 'You have unsaved changes.';

    protected init($root: JQuery): void {

        $root.find('[data-warn-on-unsaved-changes]').each((i, container) => {
            let submitted = false;
            let formHasChanged = false;

            const $container = $(container);
            $container.find('form input, form select, form textarea').on('change', () => formHasChanged = true);
            $container.find("form").on('submit', () => submitted = true);

            $(window).on('beforeunload', (e) => {
                const canceled = $container.find("[data-cancel-warning-on-unsaved-changes]").length;
                if (!canceled && formHasChanged && !submitted) {
                    // @ts-ignore
                    (e || window.event).returnValue = $container.data('warn-on-unsaved-changes') || this.warnMessage;
                    return this.warnMessage
                }
            });
        });
    }
}