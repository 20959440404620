import showLoader from "../helpers/showLoader";
import ClassicExtension from "./ClassicExtension";

export default class ShowLoaderExtension extends ClassicExtension {

    protected init($root): void {
        /* Show loader */
        $root.find('[data-show-loader]:not([data-loader-target])').on('click change', function () {
            showLoader($(this));
        });
        $root.find('[data-show-loader][data-loader-target]').on('click change', function () {
            var target = $(this).data('loader-target');
            if (target) {
                showLoader($(target));
            }
        });
        /* / Show loader until snippet redraw */
    }
}
