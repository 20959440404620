import './leaflet/leaflet-image';
import {Naja} from "naja";

export default class ImageReposition {

    public static repositionImageRating() {
        $('.viewport.leaflet-container').show(); // čum sem
        $('[data-component-image-rating], [data-component-image-rating-view] [data-image]').imgRating().trigger('resize');
    }

    public initialize(naja: Naja): void {

        naja.addEventListener('init', () => this.init($('body')));
        // naja.snippetHandler.addEventListener('afterUpdate', (e) => this.init($(e.detail.snippet)));
    }

    private init($root): void {
        $root.find('img').on('load', ImageReposition.repositionImageRating);
    }
}
