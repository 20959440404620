import ClassicExtension from "./ClassicExtension";

export default class UserActivitiesExtension extends ClassicExtension  {

    protected init($root): void {
        // User activities filters
        $root.find('.row-filters select[name="filter[period]"]').on('change', e => {
            const $select = $(e.currentTarget);

            const $inputs = $select.closest('.row-filters').find('.date-range input');

            if ($select.val() === 'custom') {
                $inputs.removeAttr('disabled');
            } else {
                $inputs.attr('disabled', 1);
            }
        }).change();
    }
}