import iziToast from "izitoast";
import ClassicExtension from "./ClassicExtension";
const ClipboardJS = require("clipboard");

export default class ClipboardExtension extends ClassicExtension {

    protected init($root): void {
        $root.find("[data-clipboard-target]").each((i, el) => {
            const clipboard = new ClipboardJS(el);
            clipboard.on('success', () => {
                iziToast.info({message: $(el).data('success'), timeout: 1500});
            });
        });
    }
}
