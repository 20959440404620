import BaseComponent from "../../BaseComponent";

export default class UploadCountInputLabel extends BaseComponent {
    private $input: JQuery<HTMLInputElement>;
    private $count: JQuery;

    init(): void {
        this.$input = <JQuery<HTMLInputElement>>this.$component.find('input[type="file"]');
        this.$count = this.$component.find('[data-files-count]');

        this.$input.on('change', () => this.updateCount());

        this.updateCount();
    }

    private updateCount() {
        const count = this.$input[0]?.files?.length ?? 0;
        this.$count.html(count ? '&nbsp;'+count : '');
    }
}