import IExtension from "../../IExtension";
import {Naja} from "naja";
import './dependentSelectBox';

type TListener = (event, xhr, settings) => void;
type TOptions = {suggestTimeout?: number, dataLinkName?: string, dataParentsName?: string};

declare global {
    interface JQuery {
        dependentSelectBox: (
                (options?: TOptions, listener?: TListener) => JQuery<HTMLElement>
            ) | (
                (listener: TListener) => JQuery<HTMLElement>
            )
    }
}

export default class DependSelectBoxExtension implements IExtension {

    public initialize(naja: Naja): void {
        naja.addEventListener('init', () => this.init($('body')));
        naja.snippetHandler.addEventListener('afterUpdate', (e) => this.init($(e.detail.snippet)));
    }

    private init($root): void {
        $root.find('[data-dependentselectbox]')
            .not('.event-binded')
            .dependentSelectBox()
            .addClass('.event-binded')
    }
}
