import ClassicExtension from "./ClassicExtension";

export default class ScrollToElementExtension extends ClassicExtension  {

    protected init($root): void {
        this.scroll($root);
    }

    private scroll($root)
    {
        const self = this;
        $root.find("[data-scroll-to]").on('click', function() {
            const scrollTo = $(this).attr('data-scroll-to');
            if (!self.isInViewport($(scrollTo)[0])) {
                $(scrollTo)[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        });
    }

    private isInViewport(el) {

        var docViewTop = $(window).scrollTop();
        var docViewBottom = docViewTop + $(window).height();

        var elemTop = $(el).offset().top;
        var elemBottom = elemTop + $(el).height();

        return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    }

}
