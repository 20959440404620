import IExtension from "./IExtension";
import {Naja} from "naja";

export default class HideOnEmptyContentExtension implements IExtension {

    public initialize(naja: Naja): void {
        naja.snippetHandler.addEventListener('afterUpdate', (e) => {
            const snippetContent = e.detail.snippet.innerHTML.trim();

            /* Hide on empty content */
            if (!snippetContent) {
                $('[empty-payload-hide-element]').each((i, el) => {
                    const selector = $(el).data('empty-payload-hide-element');
                    if (selector) {
                        $(selector).hide();
                    }
                });
            }
        });
    }
}
