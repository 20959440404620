import BaseComponent from "../../BaseComponent";

export default abstract class BaseProjectForm extends BaseComponent
{
    public init(): void {

        this.registerEvents();
        // this.context.naja.snippetHandler.addEventListener('afterUpdate', () => this.registerEvents());
    }

    public abstract registerEvents(): void;
}
