import BaseComponent from '../../BaseComponent';
import iziToast from 'izitoast';
import { Modal } from "bootstrap";

export default class OpenAIInsightFinder extends BaseComponent {
    private defaultInsightAiFrom = 3;

    init (): void {
        if (!this.$component.hasClass('initialized')) {
            this.initAIFinder(this.$component);
            this.$component.addClass('initialized');
        } else {
            // this.checkState(this.$component);
        }
    }

    private initAIFinder (aiComponent) {
        // check tools
        this.checkOpenAiFinderTools(aiComponent);

        // init responses actions
        this.initResponses(aiComponent);
        // init share form
        this.initShareForm(aiComponent);
        // init listener
        this.initStateListener(aiComponent);
    }

    private checkOpenAiFinderTools (aiComponent) {
        // generate ai response btn
        aiComponent.find('button[name="openai-generate-btn"]').off('click').on('click', e => {
            this.callGenerate($(e.currentTarget), aiComponent);
            return false;
        });

        // show / hide panel content
        aiComponent.find('.openai-action-expand').off('click').on('click', e => {
            if ($(e.currentTarget).hasClass('btn-active')) {
                this.switchPanelExpansion($(e.currentTarget), aiComponent);
            }
        });

        // copy response
        aiComponent.find('.openai-action-copycontent').off('click').on('click', e => {
            this.copyResponse(aiComponent, $(e.currentTarget));

            return false;
        });

        // delete request
        aiComponent.find('.openai-action-delete').off('click').on('click', e => {
            const removeDialogId = '#openai-if-removedialog';
            const removeDialog = $(removeDialogId);

            // bind actions
            removeDialog.find('button[data-submit]').off('click').on('click', () => {
                this.deleteResponse(aiComponent, $(e.currentTarget));
                popup.hide();

                // move to box
                this.moveToBox(null);

                return false;
            });
            const popup = new Modal(removeDialogId);
            popup.show();

            return false;
        });

        // duplicate question
        aiComponent.find('.ico-ai-duplicate').off('click').on('click', e => {
            // disable expand arrow & show questionarea
            if (!aiComponent.find('.openai-action-expand').hasClass('hide-area')) {
                aiComponent.find('.openai-action-expand').toggleClass('hide-area');
                aiComponent.find('.openai-content-questionarea').toggle('fast');
            }

            // duplicate
            this.duplicateQuestion($(e.currentTarget), aiComponent);

            // move to box
            this.moveToBox(null);

            return false;
        });

        aiComponent.find('[name="openai-if-question"]').off('keydown').on('keydown', e => {
            if (e.ctrlKey && e.keyCode === 13) {
                this.callGenerate(aiComponent.find('button[name="openai-generate-btn"]'), aiComponent);
                return true;
            }
        });

        // init count
        const commentsCount = parseInt(aiComponent.data('discussion-postscount'));
        const finderAiFrom = parseInt(aiComponent.data('discussion-generatefrom')) !== 0 ? parseInt(aiComponent.data('discussion-generatefrom')) : this.defaultInsightAiFrom;
        const unFinishedRequestsCount = parseInt(aiComponent.data('discussion-unfinished'));

        // check counts
        if (commentsCount >= finderAiFrom && unFinishedRequestsCount === 0) {
            // share - visibility
            if (!aiComponent.find('.openai-action-share').hasClass('btn-active')) {
                aiComponent.find('.openai-action-share').addClass('btn-active');
            }

            // share - action
            aiComponent.find('.openai-action-share').off('click').on('click', e => {
                if (aiComponent.find('.openai-content-sharecontainer').hasClass('d-none')) {
                    this.showShareForm(e, aiComponent);
                } else {
                    this.hideShareForm(aiComponent);
                }

                return false;
            });

            // content - visibility
            if (aiComponent.find('.openai-content-responses .openai-content-responses-template').length > 0 && aiComponent.find('.openai-content-responses').hasClass('d-none')) {
                aiComponent.find('.openai-content-default').addClass('d-none');
                aiComponent.find('.openai-content-generatebtn').removeClass('d-none');
                aiComponent.find('.openai-content-responses').removeClass('d-none');
            } else if (aiComponent.find('.openai-content-responses .openai-content-responses-template').length === 0) {
                aiComponent.find('.openai-content-default').addClass('d-none');
                aiComponent.find('.openai-content-responses').addClass('d-none');
                aiComponent.find('.openai-content-generatebtn').removeClass('d-none');
            }
        } else {
            aiComponent.find('.openai-action-share').off('click').removeClass('btn-active');
        }
    }

    private appendResponse (aiComponent, data) {
        // replace content
        this.showContent(aiComponent);

        // question area
        if (data.htmlQuestionArea) {
            aiComponent.find('.openai-content-questionarea').html($(data.htmlQuestionArea));
        }

        // responses list
        aiComponent.find('.openai-content-responses').html($(data.html));

        // find / hide error
        setTimeout(function () {
            aiComponent.find('.openai-if-content-error').hide(500);
        }, 10000);

        // set component data
        if (data.data) {
            aiComponent.data('discussion-unfinished', data.data.unfinishedResponsesCount);
        }
        // init responses actions
        this.initResponses(aiComponent);
    }

    private checkStateHandler (aiComponent) {
        // init
        const that = this;
        const location = window.location.toString();
        const checkstateUrl = location.replace('#', '') + '&do=aiInsightFinder-requeststate';

        // set processing
        (window as any).checkIfStateProcess = true;
        $.ajax({
            url: checkstateUrl,
            method: 'GET',
            data: {
                discussionId: aiComponent.attr('data-discussion-id')
            },
            success: data => {
                if (data.ok) {
                    // clear interval
                    clearInterval((window as any).checkIfStateInterval);
                    // refresh component
                    if (data.html) {
                        that.appendResponse(aiComponent, data);
                    }
                    // check tools
                    that.checkOpenAiFinderTools(aiComponent);
                } else {
                    // console.log('request unfinished');
                }
            },
            error: () => {
                clearInterval((window as any).checkIfStateInterval);
            },
            complete: () => {
                (window as any).checkIfStateProcess = false;
            }
        });
    }

    // Init responses actions
    private initResponses (aiComponent) {
        // show all responses
        aiComponent.find('a.openai-content-showmorebtn').on('click', e => {
            if (aiComponent.find('.openai-content-responses .openai-content-responses-template.d-none').length > 0) {
                aiComponent.find('.openai-content-responses .openai-content-responses-template').removeClass('d-none');

                // check if there are any other posts to show
                if (parseInt($(e.currentTarget).data('finished')) >= parseInt($(e.currentTarget).data('offset'))) {
                    $(e.currentTarget).hide();
                }
            } else {
                this.loadMore(aiComponent, $(e.currentTarget));
            }

            return false;
        });

        // show full response
        aiComponent.find('a.openai-content-showtextbtn').on('click', e => {
            $(e.currentTarget).prev().addClass('full');
            $(e.currentTarget).addClass('d-none');

            return false;
        });
    }

    private initShareForm (aiComponent) {
        aiComponent.find('input[name="email"]').on('change', e => {
            if ($(e.currentTarget).hasClass('has-error')) {
                $(e.currentTarget).removeClass('has-error');
                aiComponent.find('.openai-content-shareerror').addClass('d-none');
            }
        });

        aiComponent.find('.openai-share-emailbtn').on('click', e => {
            this.sendToEmail(aiComponent, $(e.currentTarget).closest('.openai-content-shareform'));

            return false;
        });

        aiComponent.find('.openai-share-cancelbtn').on('click', () => {
            this.hideShareForm(aiComponent);

            return false;
        });
    }

    private initStateListener (aiComponent) {
        const that = this;
        const unFinishedRequestsCount = parseInt(aiComponent.data('discussion-unfinished'));
        if (unFinishedRequestsCount > 0) {
            (window as any).checkIfStateInterval = setInterval(() => {
                if (!(window as any).checkIfStateProcess) {
                    that.checkStateHandler(aiComponent);
                }
            }, 4000);
        }
    }

    private callGenerate (btnElement, aiComponent) {
        const that = this;
        const fetchUrl = btnElement.data('openaiurl');
        const question = $.trim(aiComponent.find('[name="openai-if-question"]').val());

        // remove alert
        aiComponent.find('.openai-content-default').addClass('d-none').removeClass('openai-if-content-error');

        // call url
        if (fetchUrl && question !== '') {
            // hide content
            this.hideContent(aiComponent);

            // Fetch the data and render the datalist element
            $.ajax({
                url: fetchUrl,
                method: 'POST',
                data: {
                    question: question
                },
                success: data => {
                    // process html response
                    if (data.html) {
                        // append response
                        that.appendResponse(aiComponent, data);

                        // check tools
                        that.checkOpenAiFinderTools(aiComponent);

                        // clear question field
                        aiComponent.find('[name="openai-if-question"]').val('');
                    }

                    // process failed response
                    if (data.ok === false) {
                        if (data.unfinished === true) {
                            // increase unfinished
                            let unFinishedRequestsCount = parseInt(aiComponent.data('discussion-unfinished'));
                            aiComponent.data('discussion-unfinished', ++unFinishedRequestsCount);
                            // call listener
                            that.initStateListener(aiComponent);
                        } else if (data.unfinished === false) {
                            // refresh error
                            if (data.error) {
                                const errorElement = $('<div class="openai-if-content-error">' + data.error + '</div>');
                                aiComponent.find('.openai-content-generatebtn').before(errorElement);

                                // find / hide error
                                setTimeout(function () {
                                    aiComponent.find('.openai-if-content-error').hide(500, function () {
                                        errorElement.remove();
                                    });
                                }, 10000);
                            }
                        }
                    }

                    // error
                    if (!data.html && data.error && data.ok === true && data.unfinished === false) {
                        const errorElement = $('<div class="openai-if-content-error">' + data.error + '</div>');
                        aiComponent.find('.openai-content-generatebtn').before(errorElement);

                        // find / hide error
                        setTimeout(function () {
                            aiComponent.find('.openai-if-content-error').hide(500, function () {
                                errorElement.remove();
                            });
                        }, 10000);
                    }
                },
                error: () => {
                    // increase unfinished
                    let unFinishedRequestsCount = parseInt(aiComponent.data('discussion-unfinished'));
                    aiComponent.data('discussion-unfinished', ++unFinishedRequestsCount);
                    // call listener
                    that.initStateListener(aiComponent);
                }
            });
        } else if (question === '') {
            aiComponent.find('.openai-content-default').removeClass('d-none').addClass('openai-if-content-error');
            aiComponent.find('[name="openai-if-question"]').focus();
        }
    }

    private copyResponse (aiComponent, btnElement) {
        const answerBox = $(btnElement).closest('.openai-if-content-text.answer');

        // copy content
        const answerContent = $(answerBox).find('div > span').html();
        navigator.clipboard.writeText(answerContent);

        // show info
        const info = aiComponent.find('.openai-action-copycontentinfo').html();
        if (info) {
            iziToast.info({
                message: info
            });
        }

        // remove style
        $(answerBox).css('opacity', '');
    }

    private deleteResponse (aiComponent, btnElement) {
        // init url
        const deleteUrl = btnElement.attr('data-openai-deleteurl');

        // hide box content & show info
        this.hideContent(aiComponent);
        aiComponent.find('.openai-content-progress .openai-content-info').addClass('d-none');
        aiComponent.find('.openai-content-progress .openai-remove-info').removeClass('d-none');
        aiComponent.find('.openai-content-responses').addClass('d-none');

        // Delete
        $.ajax({
            url: deleteUrl,
            method: 'POST',
            success: data => {
                // process html response
                if (data.html) {
                    // append response
                    this.appendResponse(aiComponent, data);

                    // check tools
                    this.checkOpenAiFinderTools(aiComponent);

                    // clear question field
                    aiComponent.find('[name="openai-if-question"]').val('');

                    // message
                    if (data.message) {
                        iziToast.success({
                            message: data.message
                        });
                    }
                }
            },
            complete: () => {
                // hide progress
                this.showContent(aiComponent);
                aiComponent.find('.openai-content-progress .openai-content-info').addClass('d-none');
                aiComponent.find('.openai-content-progress .openai-remove-info').addClass('d-none');
                aiComponent.find('.openai-content-responses').removeClass('d-none');
            },
            error: () => {
                // increase unfinished
                let unFinishedRequestsCount = parseInt(aiComponent.data('discussion-unfinished'));
                aiComponent.data('discussion-unfinished', ++unFinishedRequestsCount);

                // call listener
                this.initStateListener(aiComponent);
            }
        });
    }

    private duplicateQuestion (btnElement, aiComponent) {
        aiComponent.find('[name="openai-if-question"]').val(btnElement.parent().find('.question-content').html()).focus();
    }

    private loadMore (aiComponent, loadBtnElement) {
        // init url
        const loadMoreUrl = loadBtnElement.attr('data-openai-loadmoreurl');

        aiComponent.find('.openai-content-responses .openai-content-showmore').remove();

        // show loader
        const contentProgress = aiComponent.find('.openai-if-content .openai-content-progress').clone(true);
        contentProgress.removeClass('d-none');
        aiComponent.find('.openai-content-responses').append(contentProgress);

        // Load more
        $.ajax({
            url: loadMoreUrl,
            success: data => {
                if (data.ok) {
                    aiComponent.find('.openai-content-responses .openai-content-showmore').remove();
                    aiComponent.find('.openai-content-responses').append($(data.html));

                    aiComponent.find('.openai-content-responses .openai-content-progress').remove();

                    // check tools
                    this.checkOpenAiFinderTools(aiComponent);

                    // load more
                    this.initResponses(aiComponent);
                }
            }
        });
    }

    private moveToBox (callback) {
        const boxScrollTop = $('#openai-if-box').offset().top - $(document).find('.top-panel').height();
        if (boxScrollTop < $(document).scrollTop()) {
            if (callback) {
                callback();
            }

            $([document.documentElement, document.body]).animate({
                scrollTop: boxScrollTop
            }, 100);
        }
    }

    private sendToEmail (aiComponent, formElement) {
        // init
        const shareUrl = formElement.attr('data-openai-shareurl');
        const userEmail = $.trim(formElement.find('input[name="email"]').val());
        const formData = {
            email: userEmail,
            responseId: formElement.find('input[name="response-id"]').val()
        };

        // remove alert
        formElement.find('.openai-content-shareerror').addClass('d-none');

        // call share url
        if (shareUrl && userEmail !== '' && this.validateEmail(userEmail)) {
            // show progress
            aiComponent.find('.openai-content-shareprogress').removeClass('d-none');
            formElement.addClass('d-none');

            // Share
            $.ajax({
                url: shareUrl,
                method: 'POST',
                data: formData,
                success: data => {
                    if (data.ok) {
                        // hide share form
                        formElement.find('input[name="email"]').val('');
                        this.hideShareForm(aiComponent);

                        // message
                        if (data.message) {
                            iziToast.success({
                                message: data.message
                            });
                        }
                    } else if (data.error) {
                        formElement.find('.openai-content-shareerror').html(data.error);
                        formElement.find('.openai-content-shareerror').removeClass('d-none');
                    }
                },
                complete: () => {
                    // hide share progress
                    aiComponent.find('.openai-content-shareprogress').addClass('d-none');
                    formElement.removeClass('d-none');
                }
            });
        } else if (userEmail === '' || !this.validateEmail(userEmail)) {
            formElement.find('.openai-content-shareerror').removeClass('d-none');
        }
    }

    private switchPanelExpansion (btnElement, aiComponent) {
        btnElement.toggleClass('hide-area');
        aiComponent.find('.openai-content-questionarea').toggle('fast');
    }

    private hideContent (aiComponent) {
        aiComponent.find('.openai-content-default').addClass('d-none');
        aiComponent.find('.openai-content-questionarea').addClass('d-none');
        aiComponent.find('.openai-content-progress').removeClass('d-none');
        aiComponent.find('.openai-action-expand').removeClass('btn-active');
    }

    private showContent (aiComponent) {
        aiComponent.find('.openai-content-default').addClass('d-none');
        aiComponent.find('.openai-content-questionarea').removeClass('d-none');
        aiComponent.find('.openai-content-progress').addClass('d-none');
        aiComponent.find('.openai-action-expand').addClass('btn-active');
        aiComponent.find('.openai-content-generatebtn').removeClass('d-none');
    }

    private hideShareForm (aiComponent) {
        aiComponent.find('.openai-content-sharecontainer').addClass('d-none');
        aiComponent.find('.openai-question-container').removeClass('d-none');
        aiComponent.find('.openai-content-generatebtn').removeClass('d-none');
        aiComponent.find('.openai-content-responses').removeClass('d-none');
    }

    private showShareForm (e, aiComponent) {
        aiComponent.find('.openai-question-container').addClass('d-none');
        aiComponent.find('.openai-content-generatebtn').addClass('d-none');
        aiComponent.find('.openai-content-responses').addClass('d-none');
        aiComponent.find('.openai-content-sharecontainer').removeClass('d-none');
        if (!aiComponent.find('.openai-content-shareerror').hasClass('d-none')) {
            aiComponent.find('.openai-content-shareerror').addClass('d-none');
        }
        aiComponent.find('.openai-content-sharecontent').html($(e.currentTarget).closest('.openai-content-responses-template').clone());
        aiComponent.find('.openai-content-sharecontainer .openai-content-share').remove();
        aiComponent.find('.openai-content-sharecontainer .ico-ai-duplicate').remove();

        // move to box
        this.moveToBox(() => {
            // focus
            aiComponent.find('.openai-content-sharecontainer input[name="email"]').focus();
        });
    }

    private checkState (aiComponent) {
        const that = this;
        // init url
        const checkUrl = aiComponent.attr('data-check-url');
        // get state
        $.ajax({
            url: checkUrl,
            method: 'GET',
            data: {
                discussionId: aiComponent.attr('data-discussion-id')
            },
            success: data => {
                if (data.ok) {
                    // hide share form
                    aiComponent.data('discussion-postscount', data.commentsCount);
                    // check tools
                    that.checkOpenAiFinderTools(aiComponent);
                }
            }
        });
    }

    private validateEmail (email) {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    }
}
