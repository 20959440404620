import ClassicExtension from "./ClassicExtension";

export default class RevealLinkExtension extends ClassicExtension  {

    protected init($root): void {
        $root.find(".reveal-link").not(".event-reveal-link-binded").on('click', function(e) {
            var self = $(this);
            self.parent().children(".revealed-detail").slideDown(250);
            self.addClass("revealed");

            e.stopPropagation();
            e.preventDefault();
        }).addClass('event-reveal-link-binded');
    }
}
