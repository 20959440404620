import flatpickr from 'flatpickr';
import FlatpickrLangs from './FlatpickrLangs';
import type { Naja } from 'naja';
import IExtension from '../../IExtension';
import FlatpickrFormatter from "./FlatpickrFormatter";

export default class DateTimePicker implements IExtension {
    constructor (
        public selector = "input[type='datetime'], [data-provide='datetimepicker'], .datetimepicker"
    ) {
        this.selector = selector;
    }

    initialize (naja: Naja): void {
        this.init();
        naja.snippetHandler.addEventListener('afterUpdate', (e) => {
            this.init(e.detail.snippet);
        });
    }

    private init (root?: HTMLElement|Element): void {
        const $root = root ? $(root) : $('body');
        $.each($root.find(this.selector), (i, el) => {
            const format =  $(el).data('format');
            const options = {
                enableTime: true,
                dateFormat: 'Y-m-d H:i',
                altInputClass: $(el).attr('class'),
                altFormat: format ? FlatpickrFormatter.transformFormat(format) : 'd.m.Y H:i',
                altInput: true,
                time_24hr: format && !(format.includes('h') || format.includes('g')),
                locale: FlatpickrLangs.lang
            };

            if ($(el).is('[data-date-in-future]')) {
                options['minDate'] = 'today';
                options['onChange'] = (selectedDates, dateStr, instance: flatpickr.Instance) => {
                    if (selectedDates[0]) {
                        const now = new Date();
                        const picked = new Date(selectedDates[0]);
                        if (picked < now) {
                            instance.setDate(now);
                        }
                    }
                }
            }

            // @ts-ignore
            flatpickr(el, options);
        });
    }
}
