import Chatbot from "../Chatbot";
import m, {Vnode} from "mithril";

export default class ImageDetail {
    constructor(
        private readonly chatbot: Chatbot
    ) {
    }

    public view(): Vnode|null {
        const url = this.chatbot.state.imageDetailUrl;
        if (!url) {
            return null
        }

        return m('.image-detail', {
            onclick: () => this.chatbot.state.imageDetailUrl = null,
        }, [
            m('i.bi.bi-x-lg.p-2.cursor-pointer', {
                style: {
                    position: 'absolute',
                    right: 0,
                    top: 0,
                }
            }),
            m('img', {src: this.chatbot.state.imageDetailUrl}),
        ])
    }
}