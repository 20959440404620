import BaseComponent from "../../BaseComponent";

/**
 * Check / uncheck all checkboxes match data-check-all-selector
 * 14.11.2023 - added comparison of all checkboxes checked & process action if all checked
 */
export default class CheckAll extends BaseComponent {

    public init(): void {
        //  check selectors
        const selector = this.$component.data("check-all-selector");
        const selectorsToCheck = this.$component.data("check-allcheck-selector");
        if (!selector && !selectorsToCheck) {
            console.error("Component CheckAll missing data attribute data-check-all-selector.", this.$component);
            return;
        }

        // selector - select all
        if (selector) {
            this.$component.on('click', e => {
                const checked = this.$component.prop('checked');
                $(selector).prop('checked', checked);
            });
        }

        // selector - check checked all
        if (selectorsToCheck) {
            const target = this.$component.data("check-allcheck-target");
            this.$component.find('input[type="checkbox"]').on('click', () => {
                let allChecked = true;
                this.$component.find('input[type="checkbox"]').each((i, e) => {
                    if (!$(e).is(':checked')) {
                        allChecked = false;
                        return false;
                    }
                });

                if (allChecked) {
                    $(target).removeClass('d-none');
                } else {
                    if (!$(target).hasClass('d-none')) {
                        $(target).addClass('d-none');
                    }
                }
            });
        }
    }

}