import ClassicExtension from "./ClassicExtension";
import { Naja } from "naja";

export default class InfiniteScrollExtension extends ClassicExtension {
    private naja: Naja;

    public initialize(naja: Naja): void {
        this.naja = naja;
        super.initialize(naja);
    }

    protected init($root): void {
        const naja = this.naja;
        this.scroll($root, naja);

        $(window).on('scroll', () => {
            this.scroll($root, naja);
        });
    }

    private scroll($root, naja)
    {
        $root.find("[data-infinite-scroll]").each((e, el) => {
            if ($(el).data('infinite-scroll-ready') === false) return;

            if ($(el).data('load-button') && $(window).scrollTop() + $(window).innerHeight() >= $(el).innerHeight()) {
                $(el).data('infinite-scroll-ready', false);
                const url = $($(el).data('load-button')).attr('href');
                if (url) {
                    naja.makeRequest('GET', $($(el).data('load-button')).attr('href'), null, { history: false }).then(() => {
                        $(el).data('infinite-scroll-ready', true);
                    });
                }
            }
        });
    }
}
