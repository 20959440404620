export default function(ctx) {
var __t, __p = '';
__p += '<div data-video-respondent class="row mb-1">\n    <div class="col-12 col-md-4 mt-0 form-group">\n        <input type="hidden" name="videos[' +
((__t = (ctx.videoId)) == null ? '' : __t) +
'][respondents][' +
((__t = (ctx.respondentIndex)) == null ? '' : __t) +
'][respondent_id]" value="" />\n        <input type="text" name="videos[' +
((__t = (ctx.videoId)) == null ? '' : __t) +
'][respondents][' +
((__t = (ctx.respondentIndex)) == null ? '' : __t) +
'][respondent_name]" value="" class="form-control" />\n    </div>\n    <div class="col-12 col-md-4 mt-0 form-group">\n        <input type="text" name="videos[' +
((__t = (ctx.videoId)) == null ? '' : __t) +
'][respondents][' +
((__t = (ctx.respondentIndex)) == null ? '' : __t) +
'][respondent_email]" value="" class="form-control" />\n    </div>\n    <div class="col-12 col-md-3 mt-0 form-group">\n        <select name="videos[' +
((__t = (ctx.videoId)) == null ? '' : __t) +
'][respondents][' +
((__t = (ctx.respondentIndex)) == null ? '' : __t) +
'][respondent_type]" class="form-control respondent-types-select"></select>\n    </div>\n    <div class="col-12 col-md-1 mt-0">\n        <button class="btn btn-outline-danger" type="button" value="" formnovalidate="" data-remove-video-respondent>\n            <i class="bi bi-trash-fill"></i>\n        </button>\n    </div>\n</div>';
return __p
}