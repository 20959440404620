import TMessage from "./TMessage";
import m from "mithril";
import TConversation from "./TConversation";

type TEndpoints = {
    conversation?: string,
    send?: string,
    stream?: string,
    download?: string,
}

export default class ChatbotApi {
    constructor(
        private readonly endpoints: TEndpoints
    ) { }

    public getConversation(): Promise<TConversation> {
        const url = this.endpoints.conversation;
        if (!url) {
            throw new Error('Chatbot api missing endpoint "conversation".');
        }
        return m.request({
            method: 'GET',
            url: url,
        });
    }

    public sendMessage(message: string, onMessage: (message: TMessage) => void): Promise<void> {
        const sendUrl = this.endpoints.send;
        const streamUrl = this.endpoints.stream;
        if (!sendUrl || !streamUrl) {
            throw new Error('Chatbot api missing endpoint "send" or "stream".');
        }
        if (!window.EventSource) {
            console.error('EventSource is not supported.');
        }

        return new Promise((resolve, reject) => {
            m.request({
                method: 'POST',
                url: sendUrl,
                body: { message },
            }).then(() => {
                const source = new EventSource(streamUrl);

                source.addEventListener('message', (e: MessageEvent) => {
                    const data: TMessage = JSON.parse(e.data);
                    onMessage(data);
                    if (data.completed) {
                        source.close();
                        resolve();
                    }
                });

                source.addEventListener('error', reject);
            }, reject);
        });
    }

    public getDownloadFileUrl(fileUuid: string, thumbnail: boolean): string {
        if (!this.endpoints.download) {
            throw new Error('Chatbot api missing endpoint "download" url.');
        }
        const delimiter = this.endpoints.download.includes('?') ? '&' : '?'
        return `${this.endpoints.download}${delimiter}fileUuid=${fileUuid}&thumbnail=${thumbnail?'1':'0'}`;
    }
}
