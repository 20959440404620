import IExtension from "./IExtension";
import {Naja} from "naja";

export default class IosFileUploadFixExtension implements IExtension {

    public initialize(naja: Naja): void {
        naja.addEventListener('before', e => this.disableEmptyUpload(true));
        naja.addEventListener('complete', e => this.disableEmptyUpload(false));
    }

    private disableEmptyUpload(disable: boolean): void {
        $("input[type='file']").each((i, input) => {
            const $input = $(input);
            $input.prop('disabled', disable && !$input.val());
        })
    }
}
