import type flatpickr from 'flatpickr';
type LocaleKey = flatpickr.Options.LocaleKey;

const en = require('flatpickr/dist/l10n/default');
const th = require('flatpickr/dist/l10n/th');
const cs = require('flatpickr/dist/l10n/cs');
const sk = require('flatpickr/dist/l10n/sk');
const pl = require('flatpickr/dist/l10n/pl');
const bg = require('flatpickr/dist/l10n/bg');
const hu = require('flatpickr/dist/l10n/hu');
const et = require('flatpickr/dist/l10n/et');

require('flatpickr/');
export default class FlatpickrLangs {
    public static readonly langs = {
        th: th.Thai,
        en: en.default,
        cs: cs.Czech,
        sk: sk.Slovak,
        pl: pl.Polish,
        bg: bg.Bulgarian,
        hu: hu.Hungarian,
        et: et.Estonian
    };

    public static get lang() {
        const locale = document.getElementsByTagName('html')[0].getAttribute('lang');

        if (locale) {
            return locale.split('_')[0];
        }
        return 'en';
    }
}
