/* eslint-disable */
import IExtension from './../../IExtension';
import { Naja } from 'naja';

export default class DatagridConfirm implements IExtension {
    initialize (naja: Naja): void {
        naja.addEventListener('init', () => this.init($('body')));
        naja.snippetHandler.addEventListener('afterUpdate', (e) => this.init($(e.detail.snippet)));
        naja.addEventListener('before', (e) => this.onBefore($(e.currentTarget)));
    }

    private init ($root) {
        $root.on('click', '[data-datagrid-confirm]:not(.ajax)', function (e) {
            if (!confirm($(e.target).closest('a').attr('data-datagrid-confirm'))) {
                e.stopPropagation();
                return e.preventDefault();
            }
        });
    }

    private onBefore($snippet) {
        const confirmMessage = $snippet.data('confirm_message');
        if (confirmMessage) {
            return confirm(confirmMessage);
        }
    }
}
