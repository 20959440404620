import ComponentManager from 'nms-dashboard-js/src/ComponentManager';
import DashboardComponent from 'nms-dashboard-js/src/DashboardComponent';
import CircleProgressTemplate from './CircleProgress.ejs';
import ProgressBar from 'progressbar.js';

// @ts-ignore
window.ProgressBar = ProgressBar;

export default class CircleProgress extends DashboardComponent
{
	createChart()
	{
		const container = this._$element.find('[data-container]')[0];
		const options = {
			color: this._options.color,
			strokeWidth: 6,
			trailWidth: 2,
			easing: 'easeInOut',
			duration: 800,
			step: function(state, circle) {
				const value = Math.round(circle.value() * 100);
				circle.setText(value + '%');
			}
		};

		this._chart = new ProgressBar.Circle(container, options);
		this._chart.text.style.color = '#000000';
		this._chart.animate(this._data.data);

		return this._chart;
	}

	onUpdate(data)
	{
		if (!this._chart) {
			this.createChart();
		} else {
			this._chart.animate(this._data.data);
		}
	}

	render()
	{
		const template = this.getTemplate();
		template.setFile(CircleProgressTemplate);

		return template.render();
	}
}

ComponentManager.registerComponentClass('CircleProgress', CircleProgress);