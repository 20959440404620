import BaseComponent from '../../BaseComponent';
import iziToast from 'izitoast';

export default class OpenAIMinutes extends BaseComponent {
    private defaultMinutesAiFrom = 3;
    private defaultMinutesAiLimit = 1;

    init (): void {
        if (this.$component.data('type') === 'minutes-ai') {
            if (!this.$component.hasClass('initialized')) {
                this.initAIMinutes(this.$component);
                this.$component.addClass('initialized');
            } else {
                this.checkState(this.$component);
            }
        }
    }

    private initAIMinutes (aiComponent) {
        // check tools
        this.checkOpenAIMinutesTools(aiComponent);

        // generate ai minutes btn
        aiComponent.find('button[name="openai-generate-btn"]').on('click', e => {
            this.callGenerate($(e.currentTarget), aiComponent);
            return false;
        });

        // show refresh error
        aiComponent.find('.openai-action-refresh').on('click', () => {
            if (aiComponent.find('.openai-content-generatebtn').hasClass('d-none') &&
                aiComponent.find('.openai-content-progress').hasClass('d-none') &&
                aiComponent.find('.openai-content-default').hasClass('d-none') &&
                aiComponent.find('.openai-content-responses .openai-content-progress').length === 0) {
                aiComponent.find('.openai-content-refreshinfo').removeClass('d-none');
            }
        });

        // init responses actions
        this.initResponses(aiComponent);

        // init share form
        this.initShareForm(aiComponent);

        // init listener
        this.initStateListener(aiComponent);
    }

    private appendResponse (aiComponent, data) {
        // replace content
        this.showContent(aiComponent);
        aiComponent.find('.openai-content-responses').html($(data.html));

        // find / hide error
        setTimeout(function () {
            aiComponent.find('.openai-content-error').hide(500);
        }, 10000);

        // set component data
        if (data.data) {
            aiComponent.data('discussion-unfinished', data.data.unfinishedResponsesCount);
        }
        // init responses actions
        this.initResponses(aiComponent);
    }

    private callGenerate (btnElement, aiComponent) {
        const that = this;
        const fetchUrl = btnElement.data('openaiurl');
        if (fetchUrl) {
            // hide content
            this.hideContent(aiComponent);
            // Fetch the data and render the datalist element
            fetch(fetchUrl, {
                method: 'get'
            }).then(function (response) {
                if (response.ok) {
                    return response.json();
                }
                throw response;
            }).then(function (data) {
                if (data.html) {
                    // append response
                    that.appendResponse(aiComponent, data);
                    // check tools
                    that.checkOpenAIMinutesTools(aiComponent);
                }

                if (data.ok === false) {
                    if (data.unfinished === true) {
                        // increase unfinished
                        let unFinishedRequestsCount = parseInt(aiComponent.data('discussion-unfinished'));
                        aiComponent.data('discussion-unfinished', ++unFinishedRequestsCount);
                        // call listener
                        that.initStateListener(aiComponent);
                    } else if (data.unfinished === false) {
                        // refresh error
                        if (data.error) {
                            const errorElement = $('<div class="openai-content-error">' + data.error + '</div>');
                            aiComponent.find('.openai-content-generatebtn').before(errorElement);

                            // find / hide error
                            setTimeout(function () {
                                aiComponent.find('.openai-content-error').hide(500, function () {
                                    errorElement.remove();
                                });
                            }, 10000);
                        }
                    }
                }
            }).catch(function (error) {
                if (error.name === 'AbortError') {
                    console.log(error);
                } else {
                    console.warn(error);
                }
                // increase unfinished
                let unFinishedRequestsCount = parseInt(aiComponent.data('discussion-unfinished'));
                aiComponent.data('discussion-unfinished', ++unFinishedRequestsCount);
                // call listener
                that.initStateListener(aiComponent);
            });
        }
    }

    private checkOpenAIMinutesTools (aiComponent) {
        // init count
        const commentsCount = parseInt(aiComponent.data('discussion-postscount'));
        const minutesAiFrom = parseInt(aiComponent.data('discussion-generatefrom')) !== 0 ? parseInt(aiComponent.data('discussion-generatefrom')) : this.defaultMinutesAiFrom;
        const unFinishedRequestsCount = parseInt(aiComponent.data('discussion-unfinished'));
        const minutesAiRefreshLimit = parseInt(aiComponent.data('discussion-refreshlimit')) >= 0 ? parseInt(aiComponent.data('discussion-refreshlimit')) : this.defaultMinutesAiLimit;

        // check counts
        if (commentsCount >= minutesAiFrom && unFinishedRequestsCount === 0) {
            // refresh - visibility
            const response = aiComponent.find('.openai-content-responses .openai-content-responses-template:first-child');
            const responseCommentsCount = (response.length > 0 ? parseInt(response.data('discussion-commentscount')) : 0);
            if ((commentsCount !== responseCommentsCount || (commentsCount > 0 && minutesAiRefreshLimit === 0)) && commentsCount - responseCommentsCount >= minutesAiRefreshLimit && aiComponent.find('.openai-content-responses .openai-content-responses-template').length > 0) {
                aiComponent.find('.openai-action-refresh-highlight').removeClass('d-none').addClass('active');
                aiComponent.find('.openai-action-refresh').addClass('d-none').removeClass('active');
            } else {
                aiComponent.find('.openai-action-refresh-highlight').addClass('d-none').removeClass('active');
                aiComponent.find('.openai-action-refresh').removeClass('d-none');
            }

            // refresh - action
            aiComponent.find('.openai-action-refresh-highlight.active').off('click').on('click', e => {
                $(e.currentTarget).removeClass('highlight');
                this.callGenerate(aiComponent.find('button[name="openai-generate-btn"]'), aiComponent);
            });
            // share - action
            aiComponent.find('.openai-action-share').off('click').on('click', () => {
                if (aiComponent.find('.openai-content-sharecontainer').hasClass('d-none')) {
                    aiComponent.find('.openai-content-responses').addClass('d-none');
                    aiComponent.find('.openai-content-sharecontainer').removeClass('d-none');
                    if (!aiComponent.find('.openai-content-shareerror').hasClass('d-none')) {
                        aiComponent.find('.openai-content-shareerror').addClass('d-none');
                    }
                    aiComponent.find('.openai-content-sharecontent').html(aiComponent.find('.openai-content-responses .openai-content-responses-template:first-child').clone());
                } else {
                    aiComponent.find('.openai-content-sharecontainer').addClass('d-none');
                    aiComponent.find('.openai-content-responses').removeClass('d-none');
                }

                // hide refresh info when refreshing/re-init buttons
                if (!aiComponent.find('.openai-content-refreshinfo').hasClass('d-none')) {
                    aiComponent.find('.openai-content-refreshinfo').addClass('d-none');
                }
            });

            // content - visibility
            if (aiComponent.find('.openai-content-responses .openai-content-responses-template').length > 0 && aiComponent.find('.openai-content-responses').hasClass('d-none')) {
                aiComponent.find('.openai-content-default').addClass('d-none');
                aiComponent.find('.openai-content-generatebtn').addClass('d-none');
                aiComponent.find('.openai-content-responses').removeClass('d-none');
            } else if (aiComponent.find('.openai-content-responses .openai-content-responses-template').length === 0) {
                aiComponent.find('.openai-content-default').addClass('d-none');
                aiComponent.find('.openai-content-responses').addClass('d-none');
                aiComponent.find('.openai-content-generatebtn').removeClass('d-none');
            }
        }

        // hide refresh info when refreshing/re-init buttons
        if (!aiComponent.find('.openai-content-refreshinfo').hasClass('d-none')) {
            aiComponent.find('.openai-content-refreshinfo').addClass('d-none');
        }
    }

    private checkStateHandler (aiComponent) {
        // init
        const that = this;
        const checkstateUrl = window.location + '&do=aiMinutesBot-requesttate';
        // set processing
        (window as any).checkStateProcess = true;
        $.ajax({
            url: checkstateUrl,
            method: 'GET',
            data: {
                discussionId: aiComponent.attr('data-discussion-id')
            },
            success: data => {
                if (data.ok) {
                    // clear interval
                    clearInterval((window as any).checkStateInterval);
                    // refresh component
                    if (data.html) {
                        that.appendResponse(aiComponent, data);
                    }
                    // check tools
                    that.checkOpenAIMinutesTools(aiComponent);
                } else {
                    // console.log('request unfinished');
                }
            },
            error: () => {
                clearInterval((window as any).checkStateInterval);
            },
            complete: () => {
                (window as any).checkStateProcess = false;
            }
        });
    }

    // Init responses actions
    private initResponses (aiComponent) {
        // show all responses
        aiComponent.find('a.openai-content-showmorebtn').on('click', e => {
            if (aiComponent.find('.openai-content-responses .openai-content-responses-template.d-none').length > 0) {
                aiComponent.find('.openai-content-responses .openai-content-responses-template').removeClass('d-none');
            } else {
                this.loadMore(aiComponent, $(e.currentTarget));
            }

            // hide refresh info when refreshing/re-init buttons
            if (!aiComponent.find('.openai-content-refreshinfo').hasClass('d-none')) {
                aiComponent.find('.openai-content-refreshinfo').addClass('d-none');
            }

            return false;
        });

        // show full response
        aiComponent.find('a.openai-content-showtextbtn').on('click', e => {
            $(e.currentTarget).prev().addClass('full');
            $(e.currentTarget).addClass('d-none');

            // hide refresh info when refreshing/re-init buttons
            if (!aiComponent.find('.openai-content-refreshinfo').hasClass('d-none')) {
                aiComponent.find('.openai-content-refreshinfo').addClass('d-none');
            }

            return false;
        });
    }

    private initShareForm (aiComponent) {
        const that = this;
        aiComponent.find('input[name="email"]').on('change', e => {
            if ($(e.currentTarget).hasClass('has-error')) {
                $(e.currentTarget).removeClass('has-error');
                aiComponent.find('.openai-content-shareerror').addClass('d-none');
            }

            // hide refresh info when refreshing/re-init buttons
            if (!aiComponent.find('.openai-content-refreshinfo').hasClass('d-none')) {
                aiComponent.find('.openai-content-refreshinfo').addClass('d-none');
            }
        });

        aiComponent.find('.openai-share-emailbtn').on('click', e => {
            that.sendToEmail(aiComponent, $(e.currentTarget).closest('.openai-content-shareform'));

            // hide refresh info when refreshing/re-init buttons
            if (!aiComponent.find('.openai-content-refreshinfo').hasClass('d-none')) {
                aiComponent.find('.openai-content-refreshinfo').addClass('d-none');
            }

            return false;
        });

        aiComponent.find('.openai-share-cancelbtn').on('click', e => {
            // hide share form
            $(e.currentTarget).closest('.openai-content-shareform input[name="email"]').val('');
            aiComponent.find('.openai-action-share').trigger('click');

            // hide refresh info when refreshing/re-init buttons
            if (!aiComponent.find('.openai-content-refreshinfo').hasClass('d-none')) {
                aiComponent.find('.openai-content-refreshinfo').addClass('d-none');
            }

            return false;
        });
    }

    private initStateListener (aiComponent) {
        const that = this;
        const unFinishedRequestsCount = parseInt(aiComponent.data('discussion-unfinished'));
        if (unFinishedRequestsCount > 0) {
            (window as any).checkStateInterval = setInterval(() => {
                if (!(window as any).checkStateProcess) {
                    that.checkStateHandler(aiComponent);
                }
            }, 4000);
        }
    }

    private loadMore (aiComponent, loadBtnElement) {
        // init url
        const loadMoreUrl = loadBtnElement.attr('data-openai-loadmoreurl');

        aiComponent.find('.openai-content-responses .openai-content-showmore').remove();

        // show loader
        const contentProgress = aiComponent.find('.openai-content .openai-content-progress').clone(true);
        contentProgress.removeClass('d-none');
        aiComponent.find('.openai-content-responses').append(contentProgress);

        // Load more
        $.ajax({
            url: loadMoreUrl,
            success: data => {
                if (data.ok) {
                    aiComponent.find('.openai-content-responses .openai-content-showmore').remove();
                    aiComponent.find('.openai-content-responses').append($(data.html));

                    aiComponent.find('.openai-content-responses .openai-content-progress').remove();

                    // check tools
                    this.checkOpenAIMinutesTools(aiComponent);

                    // load more
                    this.initResponses(aiComponent);
                }
            }
        });
    }

    private sendToEmail (aiComponent, formElement) {
        // init
        const shareUrl = formElement.attr('data-openai-shareurl');
        const userEmail = $.trim(formElement.find('input[name="email"]').val());
        const formData = {
            email: userEmail,
            responseId: formElement.find('input[name="response-id"]').val()
        };

        // remove alert
        formElement.find('.openai-content-shareerror').addClass('d-none');

        // call share url
        if (shareUrl && userEmail !== '' && this.validateEmail(userEmail)) {
            // show progress
            aiComponent.find('.openai-content-shareprogress').removeClass('d-none');
            formElement.addClass('d-none');

            // Share
            $.ajax({
                url: shareUrl,
                method: 'POST',
                data: formData,
                success: data => {
                    if (data.ok) {
                        // hide share form
                        formElement.find('input[name="email"]').val('');
                        aiComponent.find('.openai-action-share').trigger('click');

                        // message
                        if (data.message) {
                            iziToast.success({
                                message: data.message
                            });
                        }
                    } else if (data.error) {
                        formElement.find('.openai-content-shareerror').html(data.error);
                        formElement.find('.openai-content-shareerror').removeClass('d-none');
                    }
                },
                complete: () => {
                    // hide share progress
                    aiComponent.find('.openai-content-shareprogress').addClass('d-none');
                    formElement.removeClass('d-none');
                }
            });
        } else if (userEmail === '' || !this.validateEmail(userEmail)) {
            formElement.find('.openai-content-shareerror').removeClass('d-none');
        }
    }

    private hideContent (aiComponent) {
        aiComponent.find('.openai-content-default').addClass('d-none');
        aiComponent.find('.openai-content-generatebtn').addClass('d-none');
        aiComponent.find('.openai-content-responses').addClass('d-none');
        aiComponent.find('.openai-content-progress').removeClass('d-none');
    }

    private showContent (aiComponent) {
        aiComponent.find('.openai-content-default').addClass('d-none');
        aiComponent.find('.openai-content-generatebtn').addClass('d-none');
        aiComponent.find('.openai-content-progress').addClass('d-none');
        aiComponent.find('.openai-content-responses').removeClass('d-none');
    }

    private checkState (aiComponent) {
        const that = this;
        // init url
        const checkUrl = aiComponent.attr('data-check-url');
        // get state
        $.ajax({
            url: checkUrl,
            method: 'GET',
            data: {
                discussionId: aiComponent.attr('data-discussion-id')
            },
            success: data => {
                if (data.ok) {
                    // hide share form
                    aiComponent.data('discussion-postscount', data.commentsCount);
                    // check tools
                    that.checkOpenAIMinutesTools(aiComponent);
                }
            }
        });
    }

    private validateEmail (email) {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    }
}
