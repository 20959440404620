import L from 'leaflet';
import ImageReposition from "./ImageReposition";
import 'leaflet.heat';
import BaseComponent from "../../BaseComponent";

export default class ImageRatingView extends BaseComponent
{
    private $img: JQuery<HTMLElement>;
    private $filter: JQuery<HTMLElement>;

	public init(): void {

        this.$img = $('[data-image]', this.$component);
        this.$filter = $('[data-filter]', this.$component);

        (new ImageReposition()).initialize(this.context.naja);

        const self = this;

        this.$img.imgRating({
            icon: {
                borderColor: '#23426f',
                backgroundColor: '#337ab7',
                textColor: '#23426f'
            },
            beforeIconCreated: function(iconOptions, data) {
                if (data.rating > 0) {
                    iconOptions.borderColor = '#3c763d';
                    iconOptions.backgroundColor = '#69AA46';
                    iconOptions.textColor = '#3c763d';
                } else if (data.rating < 0) {
                    iconOptions.borderColor = '#a94442';
                    iconOptions.backgroundColor = '#d74543';
                    iconOptions.textColor = '#a94442';
                }
            },
            onMarkerAdded: function(marker, data) {
                var rrosePopup = new L.Rrose({offset: new L.Point(0,-10), closeButton: false, autoPan: false});
                rrosePopup.setContent("<h4>" + data.userName + "</h4>" + data.note);

                marker.bindPopup(rrosePopup);
            },
            afterInit: function() {
                this.import(self.options.ratings);
            },
            onImport: function(data) {
                const heatmapData = [];

                for (let item of data) {
                    let loc = this.relposToLatLng(item.x, item.y);

                    heatmapData.push([loc.lat, loc.lng, item.rating]);
                }

                L.heatLayer(heatmapData, {minOpacity: 0.80, blur: 30, radius: 30}).addTo(this.map);
            }
        });


		$('[data-select-all-users]', this.$filter).on('click', function() {
			const $users = $('[name="users[]"]', self.$filter);

			if ($(this).is(':checked')) {
				$users.prop('checked', true);
			} else {
				$users.prop('checked', false);
			}
		});

		$('form', this.$filter).on('submit', (e) => {
            e.preventDefault();

            const $form = <JQuery<HTMLFormElement>>$(e.currentTarget);

            this.context.naja.uiHandler.submitForm($form[0]).then(payload => this.$img.imgRating('import', payload.ratings));

        });

        $('[name="rating"], [name="users[]"], [data-select-all-users]', this.$filter).on('change', function() {
            $(this).closest('form').trigger('submit');
        });
	}
}
