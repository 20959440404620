import BaseComponent from '../../BaseComponent';

export default class VideoCallTimer extends BaseComponent {
    private videoCallDateTime;
    private videoCallTimeInfoText = '';
    private videoCallTimeInfoReplacer = '%videocall_start_after%';
    private timeInterval;

    public init (): void {
        this.videoCallDateTime = this.$component.data('videocall-date');

        // info text
        if (this.$component.data('videocall-text')) {
            this.videoCallTimeInfoText = this.$component.data('videocall-text');
        }

        // info text replacer
        if (this.$component.data('videocall-text-replacer')) {
            this.videoCallTimeInfoReplacer = this.$component.data('videocall-text-replacer');
        }

        // call timer
        if (this.videoCallTimeInfoText && this.videoCallTimeInfoReplacer) {
            this.initTimer();
        }
    }

    public initTimer (): void {
        this.updateClocks();
        this.timeInterval = setInterval(this.updateClocks.bind(this), 1000);
    }

    public getTimeRemaining (): any {
        const t = Date.parse(this.videoCallDateTime) - Date.parse(String(new Date()));

        return {
            total: t,
            days: Math.floor(t / (1000 * 60 * 60 * 24)),
            hours: Math.floor((t / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((t / 1000 / 60) % 60),
            seconds: Math.floor((t / 1000) % 60)
        };
    }

    public updateClocks (): void {
        const t = this.getTimeRemaining();

        if (t) {
            const timerText = ('0' + t.hours).slice(-2) + ':' + ('0' + t.minutes).slice(-2) + ':' + ('0' + t.seconds).slice(-2);
            const infoText = this.videoCallTimeInfoText.replace(this.videoCallTimeInfoReplacer, timerText);

            this.$component.html(infoText);
        }

        if (t.total <= 0) {
            clearInterval(this.timeInterval);
        }
    }
}
