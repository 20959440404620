export default class TemplateLoader {
    static templates;

    static init (component: string): void {
        this.templates = require('./' + component + '/templates');
    }

    static loadTemplate (templateName: string, data: Record<string, unknown>): HTMLTemplateElement {
        const template = document.createElement('template');
        template.innerHTML = this.templates[templateName](data);
        return template;
    }
}
