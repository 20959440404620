import BaseComponent from "../../BaseComponent";
import TemplateLoader from "../../TemplateLoader";
import ImageReposition from "../../ImageRating/js/ImageReposition";

// Project setup - Activation step
export default class PollForm extends BaseComponent {

    private static readonly SELECTOR_ADD_POLL_OPTION = '[data-add-poll-option]';
    private static readonly SELECTOR_REMOVE_POLL_OPTION = '[data-remove-poll-option]';
    private static readonly SELECTOR_QUESTION = '[data-attribute-item]';
    private static readonly SELECTOR_POLL_CONTENT = '[data-poll-id]';
    private static readonly SELECTOR_POLL_OPTION = '[data-poll-option-id]';

    public init(): void {
        TemplateLoader.init('PollForm');

        this.registerListeners();
        this.context.naja.snippetHandler.addEventListener('afterUpdate', e => {
            this.registerListeners();
        });

        const slideOptions = {
            start: () => this.$component.find('.viewport.leaflet-container').hide(),
            done: () => ImageReposition.repositionImageRating(),
        };

        $(".card.diary.not-post.new-post .dialog-controls button.cancel").on('click', function(e) {
            $(".card.diary .expanded-only").slideUp(slideOptions);
            $(".card.diary .dialog-controls").css("display", "none");
        });
    }

    private registerListeners()
    {
        this.$component.find(PollForm.SELECTOR_ADD_POLL_OPTION)
            .not('.event-bound')
            .on("click", (e) => {
                this.addPollOption(e.currentTarget);
            })
            .addClass('event-bound');

        this.$component.find(PollForm.SELECTOR_REMOVE_POLL_OPTION)
            .not('.event-bound')
            .on('click', (e) => {
                $(e.currentTarget).closest(PollForm.SELECTOR_POLL_OPTION).remove()
            })
            .addClass('event-bound');

        this.$component.find('input[type="file"]')
            .not('.event-bound')
            .on('change', (e) => {
                const input = <HTMLInputElement>e.currentTarget;
                if (input.files?.length) {
                    input.parentElement.classList.add('has-file');
                } else {
                    input.parentElement.classList.remove('has-file');
                }

                this.loadImage(input);
            })
            .addClass('event-bound');

        this.$component.find(PollForm.SELECTOR_REMOVE_POLL_OPTION)
            .not('.event-bound')
            .on('click', (e) => {
                $(e.currentTarget).closest(PollForm.SELECTOR_POLL_OPTION).remove()
            })
            .addClass('event-bound');

    }

    private addPollOption(button: HTMLElement): void {
        const content = $(button).closest(PollForm.SELECTOR_QUESTION).find(PollForm.SELECTOR_POLL_CONTENT);
        const $content = $(content);
        const image = this.readAttribute('image');
        const optionId = ($(content).attr("data-option-id") + Math.random().toString(36).substring(2)).replace('.', 'x') ; // let a random number here or conflict with DB ID will arise
        const pollId = $(content).data("poll-id");

        const template = TemplateLoader.loadTemplate('pollOption', {
            optionId: optionId,
            pollId: pollId,
            image: image
        });

        $content.append(template.content);
        $content.attr("data-option-id", optionId);

        this.registerListeners();
    }

    private loadImage(input: HTMLInputElement) {
        const $label = $(input.parentElement);
        $label.find('img').remove();

        const file = input.files[0];
        if (!file) {
            return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const $preview = $('<img src="" class="file-image" />');
            $preview.attr('src', ''+reader.result);
            $label.prepend($preview);
        };
    }

}
