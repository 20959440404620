/*eslint-disable */
import IComponent, {ComponentContext} from './IComponent';
import Translator from './Translator';

export default abstract class BaseComponent implements IComponent {
    public readonly translator: Translator;
    protected readonly options: null|{[property: string]: any} = null;

    /**
     * @param $component
     * jQuery element represents root of the component
     * component in latte looks like this:
     * @example
     * ```latte
     * <div n:jsComponent="ComponentName">...</div>
     * ```
     *
     * @param context
     */
    constructor (
        public readonly $component: JQuery<HTMLElement>,
        public readonly context?: ComponentContext,
    ) {
        this.translator = new Translator(this.readAttribute('translations'));
        this.options = this.$component.data('options'); // JSON parsed automatically by jQuery, thanks ;)
    }

    protected readAttribute(name): any
    {
        const attribute = this.$component.data(name);
        this.$component.removeAttr('data-' + name);
        return attribute;
    }

    public abstract init(): void;
}
/* eslint-enable */
