import BaseComponent from "../../BaseComponent";

export default class PrivateMessages extends BaseComponent {
    public init(): void {
        if ($.fn.selectize === undefined) {
            console.error('Plugin "selectize.js" is missing!');
            return;
        }

        const s = this.$component.find('.selectize')[0];
        s.selectize?.destroy();

        const selectize = this.$component.find('.selectize').selectize({
            onItemAdd: (value: any, item: JQuery) => {
                const $component = item.closest('[data-private-messages-conversation-url]');
                const endpointUrl = $component.data('private-messages-conversation-url');
                if (endpointUrl) {
                    let canLeavePage = true;
                    if ($component.find('.message-input textarea').val()) {
                        canLeavePage = confirm($component.data('msg-confirm-leave-page'));
                    }
                    if (canLeavePage) {
                        $.getJSON(endpointUrl, {'recipientId': value}, (data) => {
                            if (data.url) {
                                window.location.href = data.url;
                            }
                        });
                    }
                }
            },
        });

        this.$component.find('.selectize-input').on('click focus', () => selectize[0].selectize.clear());

        // Scroll bottom on page/snippet init
        const $messages = this.$component.find('.private-messages-conversation-detail');
        if ($messages.length > 0) {
            $messages.animate({ scrollTop: $messages.prop("scrollHeight")}, 0);
        }
    }
}
