import Cookies from 'js-cookie';
import BaseComponent from "../../BaseComponent";


export default class UsersImport extends BaseComponent
{
    private downloadError: number = 0;

	public init(): void {
        this.initDownloadPart();
        this.initUploadPart();
    }

    private initDownloadPart(): void {
        const $downloadPart = this.$component.find('.import-download-template');
        Cookies.set('userImportDownloadTemplate', 'pending');

        const $downloadButton = $downloadPart.find('.step-default a');
        $downloadButton.on('click', () => {
            $downloadPart.find('.step').hide();
            $downloadPart.find('.step-downloading').show();
            this.waitForDownload();
        });
    }

    private waitForDownload(): void {
        const self = this;
        const $downloadPart = this.$component.find('.import-download-template');
	    let attempts = 20;
        let downloadStatus = 'pending';

        function loop() {
            const cookie = Cookies.get('userImportDownloadTemplate');
	        if (cookie) {
	            downloadStatus = cookie;
            }
	        attempts--;

	        if (attempts > 0 && downloadStatus === 'pending') {
	            // Just wait
                setTimeout(loop,1000)
            } else if (downloadStatus === 'success') {
	            // Download finish successfully
                Cookies.remove('userImportDownloadTemplate');
                $downloadPart.find('.step').hide();
                $downloadPart.find('.text-success').show();
            } else if (attempts === 0 || downloadStatus === 'error') {
                // Waiting timeout exceed or download failed
                Cookies.remove('userImportDownloadTemplate');

                $downloadPart.find('.step').hide();
                $downloadPart.find('.step-default').show();
                self.downloadError++;
	            if (self.downloadError < 2) {
	                // failed first time
                    $downloadPart.find('.text-danger').show();
                } else {
	                // failed again
                    $downloadPart.find('.text-danger').show();
                }
            }
        }

	    loop();
    }

    initUploadPart()
    {
        const $uploadPart = this.$component.find('.import-upload-template');

        const $uploadButton = $uploadPart.find('.step-default button.btn-primary');
        $uploadButton.on('click', () => {
            if (this.$component.find('#frm-userImport-form-file').val()) {
                $uploadPart.find('.step').hide();
                $uploadPart.find('.step-uploading').show();
            }
        });
    }
}
