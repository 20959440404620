import IExtension from "./IExtension";
import {Naja} from "naja";

export default class UserFreeAccountChecker implements IExtension {

	initialize(naja: Naja): void {
		if ($('body .top-free-info').length > 0) {
			const projectId = $('body .top-free-info').attr('data-project-id');
			const fetchUrl = $('body .top-free-info').attr('data-url');
			setInterval(() => this.refreshTime(naja, projectId, fetchUrl), 60000);
		}
	}

	refreshTime(naja: Naja, projectId, url): void {
		console.log('make-request');
		naja.makeRequest('GET', url).then(() => {
			console.log('request-response');
		});
	}
}
