import './components/LineChart';
import './components/DoughnutChart';
import './components/CircleProgress';
import './components/Ratings';
import './components/EmotionChart';
import './components/CustomerJourney';
import componentManager from 'nms-dashboard-js';
import IExtension from "../../IExtension";
import {Naja} from "naja";


export default class DashboardExtension implements IExtension {
    private naja: Naja;

    public initialize(naja: Naja): void {
        this.naja = naja;

        this.init(true);
        naja.snippetHandler.addEventListener("afterUpdate", (e) => {
            if (e.detail.snippet.id === 'snippet-filter-') {
                this.init(false);
            }
        });
    }

    private init(sendInit = true) {
        const $dashboardFilter = $(document).find('[data-dashboard-filter]');

        if ($dashboardFilter.length) {
            componentManager.setAjaxTransport(this.ajaxCall.bind(this));
            componentManager.setAttributeNamespace('db');
            componentManager.setFilter($dashboardFilter);
            componentManager.setDataEndpoint($dashboardFilter.data('endpoint'));
            componentManager.setLoadingText($dashboardFilter.data('loading-text'));
            componentManager.setSendInitialRequest(sendInit);
            componentManager.run(document.body);

            $dashboardFilter.on('change', function(e) {
                componentManager.applyFilter();
            });
        }
    }

    private ajaxCall(settings: any) {
        settings.success = (payload) => {
            if (payload?.snippets) {
                this.naja.snippetHandler.updateSnippets(payload.snippets);
            }
        }
       return $.ajax(settings);
    }
}
