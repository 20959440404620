import IExtension from "./IExtension";
import {Naja} from "naja";

export default class OpenModalsExtension implements IExtension {
    initialize(naja: Naja): void {
        naja.addEventListener("complete", e => {
            this.openModals(e.detail.payload?.openModals || {});
        });
    }

    private openModals(modals) {
        for (var key in modals) {
            var modalId = modals[key];
            $('#' + modalId).modal('show');
        }
    }
}