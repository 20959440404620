import IExtension from "./IExtension";
import {Naja} from "naja";

export default class ActionAfterChangeExtension implements IExtension {
    private naja: Naja;

    initialize(naja: Naja): void {
        this.naja = naja;

        naja.addEventListener("init", () => this.init($('body')));
        naja.snippetHandler.addEventListener("afterUpdate", (e) => this.init($(e.detail.snippet)));
    }

    private init($root: JQuery<Element>): void {

        $root.find('.action-after-change[data-action]').on('change', e => {
            const $input = $(e.currentTarget);
            const action = $input.data('action');

            this.naja.makeRequest(
                'GET',
                action,
                {value: $input.val()},
                { off: ['unique'] } // should disable unique extension, but how with naja
            );

        });
    };
}