import BaseComponent from "../../BaseComponent";

/**
 * Load widgets content with ajax after page was loaded
 */
export default class DashboardWidgets extends BaseComponent {

    init(): void {
        const oneAjaxRequestUrl = this.$component.data('one-ajax-request-url');
        if (oneAjaxRequestUrl) {
            this.loadWidget(oneAjaxRequestUrl, this.$component); // load all widgets with one request
        } else {
            this.$component.find('[data-ajax-url]').each((i, el) => {
                const $el = $(el);
                this.loadWidget($el.data('ajax-url'), $el);
            });
        }

    }

    private loadWidget(url: string, $el: JQuery): void {
        this.context.naja
            .makeRequest('GET', url, null, {unique: false})
            .then( () => $el.find('.loader').remove() );
    }
}
