import BaseComponent from '../../BaseComponent';

export default class BulkEmailForm extends BaseComponent {
    public init (): void {
        this.initSelect(this.$component.find('select[name="type"]'));
    }

    public initSelect (selectElement): void {
        const that = this;
        selectElement.on('change', function () {
            that.processSelectType($(this).val());
        });
    }

    public processSelectType (templateType): void {
        this.$component.find('input, textarea').each( (i, input) => {
            const $input = $(input);

            // default value
            const value = $input.data('emailform-' + templateType + '-value');
            if (typeof value === 'string') {
                $input.val(value)
                window.ckeditors?.[$input.attr('id')]?.setData(value);
            }

            // placeholder
            const placeholder = $input.data('emailform-' + templateType + '-placeholder');
            if (typeof placeholder === 'string') {
                $input.attr('placeholder', placeholder);
            }
        });
    }
}
