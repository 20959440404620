import IExtension from "../../IExtension";
import {Naja} from "naja";

export default class DatagridInstantUrlRefresh implements IExtension {
    private naja: Naja;

    public initialize(naja: Naja): void {
        this.naja = naja;
        naja.addEventListener("init", () => this.init());
    }

    private init(): void {
        const $datagrid = $('.datagrid');
        if ($datagrid.length) {
            this.naja.makeRequest('GET', $datagrid.first().data('refresh-state'));
        }
    }
}
