import BaseComponent from "../../BaseComponent";
import {default as iziToast, IziToastSettings} from 'izitoast';

export default class ToastMessage extends BaseComponent {
    init (): void {
        this.toast();
    }

    private toast()
    {

        const type = this.readAttribute('type');
        const settings: IziToastSettings = {
            message: this.readAttribute('message'),
            timeout: 4000,
            onClosed: (settings, el) => el.remove(),
        };

        switch (type) {
            case 'success':
                iziToast.success(settings);
                break;
            case 'warning':
                iziToast.warning(settings);
                break;
            case 'error':
                iziToast.error(settings);
                break;
            default:
                iziToast.info(settings);
        }
    }
}
