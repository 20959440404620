import IExtension from "../../IExtension";
import '../simpleLightbox/simpleLightbox';
import {Naja} from "naja";

declare global {
    interface JQuery {
        simpleLightbox: (...args: any[]) => JQuery<HTMLElement>,
    }
}

export default class LightboxExtension implements IExtension {

    public initialize(naja: Naja): void {
        naja.addEventListener('init', () => this.init($('body')));
        naja.snippetHandler.addEventListener('afterUpdate', (e) => this.init($(e.detail.snippet)));
    }

    private init($root): void {
        $root.find('.lightbox a').not('.event-binded').each((i, el) => {
            const $el = $(el);
            $el.simpleLightbox();
            $el.addClass('event-binded');
        });

        // assign lightbox to img (if not part of image rating)
        if ($root.find('.post-content div[data-component-image-rating-view] img').length === 0 && $root.find('.post-content img[data-component-image-rating]').length === 0) {
            $root.find('.post-content img').not('.event-binded').not('[src^="/build/"]').each((i, el) => {
                const $el = $(el);

                // check if is in <a>
                if ($el.parent('a').length === 0) {
                    const $imgClone = $el.clone();
                    const $aEl = $('<a>');
                    $aEl.attr('href', $el.attr('src'));
                    $aEl.append($imgClone);
                    $el.replaceWith($aEl);

                    $aEl.simpleLightbox();
                    $aEl.addClass('event-binded');
                } else {
                    const $aEl = $el.parent('a');
                    if (!$aEl.hasClass('event-binded')) {
                        $aEl.simpleLightbox();
                        $aEl.addClass('event-binded');
                    }
                }
            });
        }
    }
}
