import BaseComponent from "../../BaseComponent";

/**
 * Check / uncheck checkbox match data-disable-elements-selector
 */
export default class DisableElements extends BaseComponent {

	public init(): void {
		const selector = this.$component.data("disable-elements-selector");

		if (!selector) {
			console.error("Component DisableElements missing data attribute data-disable-elements-selector.", this.$component);
			return;
		}
		this.$component.on('change', e => {
			const checked = this.$component.prop('checked');
			$(selector).prop('disabled', (checked ? 'disabled' : ''));
		});
	}

}