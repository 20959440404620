import ClassicExtension from "./ClassicExtension";

/*  Youtube embed videos - resizing */
export default class YoutubeVideosExtension extends ClassicExtension {

    protected init($root): void {
        // Find all YouTube videos
        this.getVideos($root).each((i, iframe) => {
            // Figure out and save aspect ratio for each video
            const $iframe = $(iframe);
            $iframe.data('aspectRatio', $iframe.height() / $iframe.width())
                // and remove the hard coded width/height
                .removeAttr('height')
                .removeAttr('width');
        });

        // When the window is resized
        $(window).on('resize', () => this.onResize());

        // Kick off one resize to fix all videos on page load
        this.onResize();
    }

    private getVideos($root: JQuery): JQuery {
        return $root.find("iframe[src*='www.youtube']");
    }

    private onResize(): void {
        // Resize all videos according to their own aspect ratio
        this.getVideos($('body')).each((i, video) => {
            const $video = $(video);
            const parentWidth = $video.parent().width();
            const maxWidth = $video.data('max-width') ? $video.data('max-width') : 560;
            const width = Math.min(parentWidth, maxWidth);

            $video.width(width).height(width * $video.data('aspectRatio'));
        });
    }



}
