import UploadAvatarForm from "../../UploadAvatarForm";

export default class UploadAreaForm extends UploadAvatarForm {
    init (): void {
        super.init();

        this.$component.find('button[name="_submit"]').on('click', () => {

            // create canvas
            const canvas = this.cropper.getCroppedCanvas();
            const userAvatarArea = document.getElementById('user-avatar-area');
            userAvatarArea.classList.add('active');
            const userAvatarImage = document.getElementById('user-avatar-image');
            userAvatarImage.append(canvas);

            this.$component.modal('hide');
        });
    }

    protected onHideModal() {
        this.cropper?.destroy();
        delete this.cropper;
    }
}
