import BaseComponent from '../../BaseComponent';

declare global {
    interface JQuery {
        inlineEdit: (...args: any[]) => JQuery<HTMLElement>,
    }
}

export default class CommentForm extends BaseComponent {
    public init (): void {
        this.$component.not('.send-event-binded').on('submit', e => {
            // Send & disable the form
            this.context?.naja?.uiHandler.submitForm(<HTMLFormElement>e.currentTarget);
            $(e.currentTarget).find(':input, button').attr('disabled', 'disabled');
        }).addClass('send-event-binded');

        const card = this.$component.closest('.card');

        // Comment inline editation
        const $commentBlockEditable = card.find('.comment-block .editable').not('.event-binded');
        $commentBlockEditable.inlineEdit();
        $commentBlockEditable.addClass('event-binded');
    }
}
