import ClassicExtension from "./ClassicExtension";

/**
 * Prevent enter to submit form
 * On press enter jumps to next field
 */
export default class PreventEnterSubmitExtension extends ClassicExtension  {

    protected init($root): void {
        $root.find('form input.prevent-enter-submit').keydown(function (e) {
            if (e.keyCode === 13) {
                const inputs = $(this).parents("form").eq(0).find(":input");
                if (inputs[inputs.index(this) + 1] != null) {
                    inputs[inputs.index(this) + 1].focus();
                }
                e.preventDefault();
                return false;
            }
        });
    }
}
