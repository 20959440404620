import IExtension from "./IExtension";
import {Naja} from "naja";

export default class RevealMainContentExtension implements IExtension {
    public initialize(naja: Naja): void {
        const $mainContent = $('body > .loading');
        naja.addEventListener('init', () => {
            $mainContent.removeClass('loading').addClass('loaded');
        });
    }
}
