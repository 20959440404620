import ComponentManager from 'nms-dashboard-js/src/ComponentManager';
import DashboardComponent from 'nms-dashboard-js/src/DashboardComponent';
import RatingsTemplate from './Ratings.ejs';
import ProgressBar from 'progressbar.js';

export default class Ratings extends DashboardComponent
{
	createChart()
	{
		const $ratings = this._$element.find('[data-rating]');
		const options = {
			strokeWidth: 6,
			easing: 'easeInOut',
			duration: 800,
			color: '#f8bb17',
			trailColor: '#eee',
			trailWidth: 2,
			svgStyle: {width: '100%', height: '6px'},
			text: {
				style: {
					color: '#000000'
				}
			},
			step: (state, bar) => {
				bar.setText(Math.round(bar.value() * 100) + '%');
			}
		};

		this._chart = {};

		$ratings.each((index, element) => {
			let $rating = $(element);
			let key = $rating.data('rating');
			let line = new ProgressBar.Line(element, options);
			line.animate(this._data.data[key]);
			this._chart[key] = line;
		});

		return this._chart;
	}

	onUpdate(data)
	{
		if (!this._chart) {
			this.createChart();
		} else {
			$.each(this._chart, (key, line) => {
				if (this._data.data[key] !== undefined) {
					line.animate(this._data.data[key]);
				}
			});
		}
	}

	render()
	{
		const template = this.getTemplate();
		template.setFile(RatingsTemplate);

		return template.render();
	}
}

ComponentManager.registerComponentClass('Ratings', Ratings);