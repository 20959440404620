import flatpickr from 'flatpickr';
import FlatpickrLangs from './FlatpickrLangs';
import type { Naja } from 'naja';
import IExtension from '../../IExtension';
import FlatpickrFormatter from "./FlatpickrFormatter";

export default class DatePicker implements IExtension {
    constructor (
        public selector = "input[type='date'], [data-provide='datepicker'], .datepicker"
    ) {
        this.selector = selector;
    }

    initialize (naja: Naja): void {
        this.init();

        naja.snippetHandler.addEventListener('afterUpdate', (e) => {
            this.init(e.detail.snippet);
        });
    }

    private init (root?: HTMLElement|Element): void {
        const $root = root ? $(root) : $('body');
        $.each($root.find(this.selector), (i, el) => {

            const options = {
                dateFormat: 'Y-m-d',
                altInputClass: $(el).attr('class'),
                altFormat: $(el).data('format') ? FlatpickrFormatter.transformFormat($(el).data('format')) : 'd.m.Y',
                altInput: true,
                locale: FlatpickrLangs.lang,
            };

            if ($(el).is('[data-date-in-future]')) {
                options['minDate'] = 'today';
            }

            // @ts-ignore
            flatpickr(el, options);
        });
    }
}
