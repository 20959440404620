import IExtension from "./IExtension";
import {Naja} from "naja";

/**
 * Make ajax request on window/snippet load
 */
export default class OnLoadAjaxExtension implements IExtension {
    private naja: Naja;

    initialize(naja: Naja): void {
        this.naja = naja;
        naja.addEventListener("init", () => this.init($('body')));
        naja.snippetHandler.addEventListener("afterUpdate", (e) => this.init($(e.detail.snippet)));
    }

    private init($root: JQuery<Element>): void {
        $root.find('[data-onload-ajax]').each((i, el) => {
            const $el = $(el);
            const action = $el.data('action');
            if (!action) {
                console.warn("OnLoadAjaxExtension: Url of data-action not found.", el);
                return;
            }
            if (!$el.data('loaded')) {
                this.naja.makeRequest('GET', action, null, {unique: false});
            }
        });
    };
}
