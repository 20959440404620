import BaseComponent from '../../BaseComponent';

export default class LanguageSwitch extends BaseComponent {
    public init (): void {
        const that = this;
        this.$component.find('.localization-select a[data-lang]').each(function (index, element) {
            $(element).on('click', function () {
                that.unSelectLangBtns();
                $(this).addClass('btn-primary');
                that.switchLang($(this).attr('data-lang'));
            });
        });
    }

    private switchLang (selectedLang) {
        $(document).find('input[data-language], textarea[data-language], div[data-language]').addClass('d-none');
        $(document).find('input[data-language="' + selectedLang + '"], textarea[data-language="' + selectedLang + '"], div[data-language="' + selectedLang + '"]').removeClass('d-none');
    }

    private unSelectLangBtns () {
        this.$component.find('.localization-select a[data-lang]').removeClass('btn-primary');
    }
}
