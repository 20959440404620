import IExtension from "../IExtension";
import {Naja} from "naja";
require('@selectize/selectize');
//require('./SelectizeForNette')
import '@selectize/selectize/dist/css/selectize.bootstrap5.css';

export default class SelectizeExtension implements IExtension {

    public naja: Naja;

    public initialize(naja: Naja): void {
        if ($.fn.selectize === undefined) {
            console.error('Plugin "selectize.js" is missing!');
        }

        this.naja = naja;
        naja.addEventListener('init', () => this.init($('body')));
        naja.snippetHandler.addEventListener('afterUpdate', (e) => this.init($(e.detail.snippet)));
    }

    private init($root): void {
        $root.find('.selectize').each((i, el) => {
            const $el = $(el);
            if (!el.selectize && !$el.is('div')) {

                const selectizeOptions = {
                    plugins: ['remove_button'],
                    onInitialize: () => {
                        // Fix bootstrap select classes
                        ['form-select', 'rounded'].forEach(htmlClass => {
                            if ($el.hasClass(htmlClass)) {
                                $el.parent().find('.selectize-input').addClass(htmlClass);
                            }
                        })
                    },
                    onDropdownOpen: ($dropDown: JQuery<HTMLElement>) => {
                        // Fix bootstrap select classes
                        if ($dropDown.hasClass('form-select')) {
                            $dropDown.removeClass('form-select');
                        }
                    },
                };

                const settings = $el.data('options') || {};

                this.addCreate(settings, selectizeOptions);

                $el.selectize(selectizeOptions);
            }
        });
    }

    /**
     * Add create function to selectized element
     * @param settings
     * @param options
     * @private
     */
    private addCreate(
        settings: {createUrl?: string, createText?: string},
        options: Selectize.IOptions<any, any>
    ): void {
        const createUrl = settings.createUrl;
        if (!createUrl) {
            return;
        }

        const self = this;
        options.create = function (query, callback) {
            if (!query.length || query.length < this.minSearchLength) {
                return callback();
            }

            const selectize: Selectize.IApi<any, any> = this;
            self.naja.makeRequest(
                'GET',
                createUrl,
                {query: query})
            .then(
                res => {
                    if (res.id) {
                        selectize.addOption({value: res.id, text: query});
                        selectize.setValue(res.id)
                        selectize.refreshOptions(true);
                        callback(res);
                    }
                },
                () => {
                    console.error('AJAX error');
                    callback();
                }
            );
        };


        if (settings.createText) {
            options.render = options.render || {};
            options.render.option_create = (data, escape) => `<div class="create">${settings.createText} <strong>${escape(data.input)}</strong>&hellip;</div>`;
        }
    }
}
