import ClassicExtension from "./ClassicExtension";

/**
 * Click on given target
 */
export default class ClickTargetExtension extends ClassicExtension {

    protected init($root: JQuery) {
        $root.find("[data-click-target]").not('.event-binded').on('click', e => {
            const target = e.currentTarget.dataset.clickTarget;
            if (!target) {
                return;
            }

            const $target = $(target);
            if (!$target.length) {
                console.warn(`Click target "${target}" not found.`)
                return;
            }

            e.stopPropagation();
            e.preventDefault();

            $target.first().trigger('click');

        }).addClass('event-binded');
    }

}