import BaseComponent from '../../BaseComponent';
import iziToast from "izitoast";

export default class OpenAITopicReport extends BaseComponent {
    init (): void {
        this.initEvents();
    }

    addQuestionRow (btnElement:JQuery<HTMLElement>): JQuery<HTMLElement>
    {
        const questionsArea = btnElement.closest('#questions-area');
        const questionRow = questionsArea.find('[data-question-row]:first').clone();
        questionRow.find('input').val('');
        questionRow.insertBefore(btnElement);

        this.initEvents();

        return questionRow;
    }

    attachQuestionEvent (inputElement: HTMLElement): void
    {
        const that = this;
        $(inputElement).off('keypress').on('keypress', e => {
            if (e.which === 13 ) {
                e.preventDefault();
                const questionRow = that.addQuestionRow(that.$component.find('#questions-area [data-add-question]'));
                questionRow.find('input').focus();
            }
        });
    }

    generateReport (linkElement: HTMLElement, formElement: JQuery<HTMLFormElement>): void
    {

        const that = this;
        this.sendTopicForm(linkElement, formElement, function() {
            that.$component.find('[data-report-form]').addClass('d-none');
            that.$component.find('[data-report-processing]').removeClass('d-none');
        }, function() {
            console.log('send success');
        }, function(data) {
            if (data.responseJSON && data.responseJSON.errors) {

                that.$component.find('[data-report-form]').removeClass('d-none');
                that.$component.find('[data-report-processing]').addClass('d-none');

                that.$component.find('[data-errors]').empty();
                for (let index in data.responseJSON.errors) {
                    that.$component.find('[data-errors]').append(data.responseJSON.errors[index]);
                }
                that.$component.find('[data-errors]').removeClass('d-none');
            }
        });
    }

    removeQuestionRow (btnElement:JQuery<HTMLElement>): void
    {
        const questionsArea = btnElement.closest('#questions-area');
        if (questionsArea.find('[data-question-row]').length > 1) {
            btnElement.closest('[data-question-row]').remove();
        } else {
            btnElement.closest('[data-question-row]').find('input').val('');
        }

        this.reindexQuestions(questionsArea);
    }

    reindexQuestions (questionsArea: JQuery<HTMLElement>): void
    {
        questionsArea.find('input').each(function(index, element) {
            $(element).attr('name', $(element).attr('name').replace(/[0-9]+/g, index.toString()));
        });
    }

    resetForm (linkElement: HTMLElement, formELement: JQuery<HTMLFormElement>): void
    {
        formELement.find('input[name="name"]').val('');
        formELement.find('input[type="checkbox"]').prop('checked', false);
        formELement.find('select[name="_length"]').val('');
        formELement.find('textarea[name="context"]').val('');
        formELement.find('#questions-area .added-questions [data-question-row]').remove();
        formELement.find('#questions-area [data-question-row] input').val('');

        const formData = {
            topicId: formELement.find('[name="topicId"]').val()
        };
        const url = linkElement.getAttribute('href');

        // Share
        $.ajax({
            url: url,
            method: 'POST',
            data: formData
        });
    }

    saveForm (linkElement: HTMLElement, formElement: JQuery<HTMLFormElement>): void
    {
        const that = this;
        this.sendTopicForm(linkElement, formElement, function() {}, function(data) {
            if (data.ok) {

                // message
                if (data.message) {
                    iziToast.success({
                        message: data.message
                    });
                }
            } else if (data.error) {
                formElement.find('[data-errors]').html(data.error);
                formElement.find('[data-errors]').removeClass('d-none');
            }
        }, function(data) {
            if (data.responseJSON && data.responseJSON.errors) {

                that.$component.find('[data-errors]').empty();
                for (let index in data.responseJSON.errors) {
                    that.$component.find('[data-errors]').append(data.responseJSON.errors[index]);
                }
                that.$component.find('[data-errors]').removeClass('d-none');
            } else {
                that.$component.modal('hide');
            }
        });
    }

    sendTopicForm (linkElement: HTMLElement, formElement: JQuery<HTMLFormElement>, afterFormCheck, successHandler, completeHandler): void
    {
        // init request data
        const url = linkElement.getAttribute('href');
        let formData = {
            topicId: formElement.find('[name="topicId"]').val(),
            name: formElement.find('[name="name"]').val(),
            posts: [],
            length: formElement.find('[name="_length"]').val(),
            language: formElement.find('[name="language"]').val(),
            context: formElement.find('[name="context"]').val(),
            questions: []
        };
        formElement.find('div[data-container="posts"] input:checked').each(function(index, element){
            let matchedNameId = $(element).attr('name').match(/\d+/);
            if (matchedNameId && matchedNameId[0]) {
                formData.posts[index] = matchedNameId[0];
            }
        });
        formElement.find('#questions-area input').each(function(index, element){
            formData.questions[index] = $(element).val();
        });

        // check fields
        if (formElement.find('[name="name"]').val() === '') {
            if (!formElement.find('[name="name"]').hasClass('has-error')) {
                formElement.find('[name="name"]').addClass('has-error');
                formElement.find('[name="name"]').prev().addClass('has-error');
            }
            formElement.find('[name="name"]').focus();
            return;
        }
        if (formElement.find('[name^="posts"]:checked').length === 0) {
            formElement.find('#posts_select_label').addClass('has-error');
            formElement.find('[name^="posts"]:first').focus();
            return;
        } else {
            formElement.find('#posts_select_label').removeClass('has-error');
        }

        // remove alert
        if (!formElement.find('[data-errors]').hasClass('d-none')) {
            formElement.find('[data-errors]').addClass('d-none');
        }

        // call
        afterFormCheck();

        // Share
        $.ajax({
            url: url,
            method: 'POST',
            data: formData,
            success: successHandler,
            complete: completeHandler
        });
    }

    initEvents (): void
    {

        // save form event
        this.$component.find('[data-add-question]').off('click').on('click', e => {
            this.addQuestionRow($(e.currentTarget));
            return false;
        });

        // remove row event
        this.$component.find('[data-remove-question]').off('click').on('click', e => {
            this.removeQuestionRow($(e.currentTarget));
            return false;
        });

        // save form
        this.$component.find('[data-save-form]').off('click').on('click', e => {
            this.saveForm(e.currentTarget, this.$component.find('form'));
            return false;
        });

        // reset form
        this.$component.find('[data-reset-form]').off('click').on('click', e => {
            this.resetForm(e.currentTarget, this.$component.find('form'));
            return false;
        });

        this.$component.find('[data-generate-form]').off('click').on('click', e => {
            this.generateReport(e.currentTarget, this.$component.find('form'));
            return false;
        })

        // reindex question inputs
        this.reindexQuestions(this.$component.find('#questions-area'));

        // attach enter on questions inputs
        const that = this;
        this.$component.find('#questions-area input').each(function(i, e) {
            that.attachQuestionEvent(e);
        });
    }

}