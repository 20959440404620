import ClassicExtension from './ClassicExtension';
import { Popover } from 'bootstrap';

export default class PopoversExtension extends ClassicExtension {
    private popoverUrlData: {[popoverId: string]: string} = {};
    protected init ($root): void {
        $root.find('.has-popover, [data-bs-toggle="popover"], [data-toggle="popover"]').each((index, el) => {
            const addonSettings: Partial<Popover.Options> = {};

            // data-bs-target - to target html element as popover content
            if ($(el).data('bs-target')) {
                addonSettings.html = true;
                addonSettings.content = $($(el).data('bs-target'));
                addonSettings.fallbackPlacements = ['bottom'];
            }

            // set popover position in DOM back to original
            addonSettings.container = $(el).closest('div:visible')[0];
            $(el).on('click', function () {
                $root.find('.has-popover, [data-bs-toggle="popover"], [data-toggle="popover"]').not(this).popover('hide');
            });

            // data-bs-options={contentUrl:url to load, url params...} - load popover ajax  content
            if (typeof $(el).data('bsOptions') !== 'undefined' && $(el).data('bsOptions').contentUrl) {

                // create loader
                addonSettings.html = true;
                addonSettings.content = $('<div class="spinner-border" role="status"></div>');

                // on show call ajax request
                const that = this;
                $(el).on('show.bs.popover', () => {
                    that.setPopoverAjaxContent(el);
                });
            }

            // create popover
            const popover = new Popover(el, addonSettings);

            // data-bs-copyfilter - copy inputs to parent filter as hidden
            if ($(el).data('bs-copyfilter')) {

                // clear opened filter
                $(el).on('show.bs.popover', () => {
                    const filterTargetInputsDivId = $(el).data('bs-target') + '-hiddendiv';
                    if ($(filterTargetInputsDivId).length > 0) {
                        $(filterTargetInputsDivId).empty();
                    }
                });

                // append filters on hide
                $(el).on('hide.bs.popover', e => {
                    const filterTarget = $(e.target).closest($(el).data('bs-copyfilter'));
                    const filterTargetInputsDivId = $(el).data('bs-target') + '-hiddendiv';
                    if ($(filterTarget).find(filterTargetInputsDivId).length  === 0) {
                        $(filterTarget).append($('<div id="' + filterTargetInputsDivId.replace('#', '') + '" class="d-none" />'));
                    }

                    // clear filters target
                    const filterTargetInputsDiv = $(filterTargetInputsDivId);
                    $(filterTargetInputsDiv).empty();

                    // append inputs to filterInputDiv
                    $(popover.tip).find('input').each((index, element) => {
                        if ($(element).attr('name') !== '' && typeof $(element).attr('name') !== "undefined") {

                            // skip if is 'checkbox' & not checked
                            if ($(element).prop('type') === 'checkbox' && !$(element).is(':checked')) {
                                return;
                            }

                            // append element to filterInputDiv
                            filterTargetInputsDiv.append($('<input type="hidden" name="' + $(element).attr('name') + '" value="' + $(element).val() + '" />'));
                        }
                    });
                });
            }

            return popover;
        });
    }

    private setPopoverAjaxContent (popoverElement): void {
        // init instance
        const popoverInstance = Popover.getOrCreateInstance(popoverElement);

        // init ajax params
        const contentUrl = $(popoverElement).data('bsOptions').contentUrl;
        const urlParams = { ...$(popoverElement).data('bsOptions') }; // clone :-) we don't want reference
        delete urlParams.contentUrl; // remove this param

        // check if already loaded
        const popoverDataKey = contentUrl + '+' + JSON.stringify(urlParams);
        if (typeof this.popoverUrlData[popoverDataKey] === 'undefined') {
            // call ajax
            const that = this;
            $.ajax({
                method: 'post',
                url: contentUrl,
                data: urlParams
            }).done((payload) => {
                let content = '';
                for (const key in payload) {
                    if (payload[key] && payload[key] !== '') {
                        content += '<div class="' + key + '">' + payload[key] + '</div>';
                    }
                }
                popoverInstance.setContent({
                    '.popover-body': content
                });

                // set dataKey loaded
                that.popoverUrlData[popoverDataKey] = content;
            });
        } else {
            popoverInstance.setContent({
                '.popover-body': this.popoverUrlData[popoverDataKey]
            });
        }

        // remove listener - we don't want to run this always
        $(popoverElement).off('show.bs.popover');
    }
}
