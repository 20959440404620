import ClassicExtension from "./ClassicExtension";

export default class RevealDiaryExtension extends ClassicExtension  {

    protected init($root): void {
        /* Diary detail reveal - Common link/reveal function */
        $root.find(".reveal-detail").not(".event-reveal-detail-binded").on('click', function(e) {
            var self = $(this);
            if(self.hasClass("revealed") == true) {
                self.parent().children(".detailed-rating").slideUp(250);
                self.removeClass("revealed");
            }
            else {
                self.parent().children(".detailed-rating").slideDown(250);
                self.addClass("revealed");
            }

            e.stopPropagation();
            e.preventDefault();
        }).addClass('event-reveal-detail-binded');
    }
}
