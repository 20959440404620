import BaseComponent from '../../BaseComponent';
import Cropper from 'cropperjs';

export default class UploadAvatarForm extends BaseComponent {

    protected cropper;
    protected $form

    init (): void {
        const $inputImage = this.$component.find('[data-cropper-input-image]');
        let $image = this.getImage();
        this.$form = <JQuery<HTMLFormElement>>$image.closest('form');

        this.createCropper($image);

        this.$component.on('hide.bs.modal', () => this.onHideModal());

        this.$component.on('show.bs.modal', () => {
            this.createCropper($image);
        });
        // Import image
        const URL = window.URL;
        let blobURL;
        if (URL) {
            $inputImage.on('change', e => {
                if ($image[0]['cropper']) {
                    $image = this.getImage();
                    this.createCropper($image);
                }
                const files = (<HTMLFormElement>e.currentTarget).files;
                let file;
                if (files && files.length) {
                    file = files[0];
                    if (/^image\/\w+$/.test(file.type)) {
                        blobURL = URL.createObjectURL(file);
                        $image.one('built.cropper', () => {
                            URL.revokeObjectURL(blobURL);
                        });
                        this.cropper.reset();
                        this.cropper.replace(blobURL);
                        $image.removeClass('d-none');
                    } else {
                        (<HTMLFormElement>this.$form[0]).reset();
                        window.alert($inputImage.data('cropper-file-type-error') || 'Invalid file type.');
                    }
                }
            });
        } else {
            $inputImage.prop('disabled', true);
        }
    }

    private getImage(): JQuery {
        return this.$component.find('[data-cropper-image]');
    }

    private createCropper($image): void {
        this.cropper?.destroy();
        delete this.cropper;
        this.cropper = new Cropper( <HTMLImageElement>$image[0], {
            aspectRatio: $image.data('aspect-ratio'),
            viewMode: $image.data('view-mode'),
            dragMode: 'move',
            toggleDragModeOnDblclick: false,
            minContainerHeight: 300,
            minCropBoxWidth: 128,
            minCropBoxHeight: 128,
            crop: e => this.onCrop(e),
        });
    }

    protected onCrop(e: Cropper.CropEvent<HTMLImageElement>): void {
        this.$form.find('input[name="x"]').val(e.detail.x);
        this.$form.find('input[name="y"]').val(e.detail.y);
        this.$form.find('input[name="w"]').val(e.detail.width);
        this.$form.find('input[name="h"]').val(e.detail.height);
    }

    protected onHideModal(): void {
        this.cropper?.destroy();
        delete this.cropper;
        (<HTMLFormElement>this.$form[0]).reset();
    }
}
