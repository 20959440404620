import ComponentManager from 'nms-dashboard-js/src/ComponentManager';
import DashboardComponent from 'nms-dashboard-js/src/DashboardComponent';
import EmotionChartTemplate from './EmotionChart.ejs';
import Chart from 'chart.js';

export default class EmotionChart extends DashboardComponent
{
	constructor(componentManager, $element, name, options) {
		super(componentManager, $element, name, options);

		this._datasetColors = [
			'#00BD36',
			'rgba(243, 243, 243, 0.5)',
			'#EA3C22',
			'rgba(243, 243, 243, 0.5)',
		];
	}

	createChart()
	{
		const ctx = this._$element.find('canvas')[0].getContext('2d');
		const options = {
			hover: {
				mode: false
			},
			maintainAspectRatio: false,
			legend: {
				display: false
			},
			tooltips: {
				filter: function(tooltipItem, data) {
					return !(tooltipItem.datasetIndex === 1 || tooltipItem.datasetIndex === 3);
				},
				callbacks: {
					title: function(tooltipItem, data) {
						return null;
					},
					label: function(tooltipItem, data) {
						return (tooltipItem.yLabel + '%').replace('-', '');
					}
				}
			},
			scales: {
				xAxes: [{
					display: false,
					stacked: true,
					gridLines: {
						display: false
					},
				}],
				yAxes: [{
					gridLines: {
						lineWidth: 2,
						zeroLineWidth: 2,
						zeroLineColor: 'rgba(0, 0, 0, 0.1)'
					},
					ticks: {
						padding: 10,
						suggestedMax: 100,
						suggestedMin: -100,
						stepSize: 25,
						callback: function(value, index, values) {
							return (value + '%').replace('-', '');
						}
					},
					scaleLabel: {
						fontColor: '#8F8F8F',
						fontSize: 10
					}
				}]
			},
		};

		this.decorateData();

		this._chart = new Chart(ctx, {
			type: 'bar',
			data: this._data.data,
			options: options
		});

		return this._chart;
	}

	onUpdate(data)
	{
		if (!this._chart) {
			this.createChart();
		} else {
			this.decorateData();
			this._chart.data.labels = this._data.data.labels;
			this._chart.data.datasets = this._data.data.datasets;
			this._chart.update();
		}

		this.updateSnippets();
	}

	decorateData()
	{
		if (!this._data) return;

		for (let i in this._data.data.datasets) {
			let dataset = this._data.data.datasets[i];

			dataset.backgroundColor = this._datasetColors[i];
			dataset.borderWidth = 0;
            dataset.barThickness = 11;
		}
	}

	render()
	{
		const template = this.getTemplate();
		template.setFile(EmotionChartTemplate);

		return template.render();
	}
}

ComponentManager.registerComponentClass('EmotionChart', EmotionChart);
