import {Naja} from "naja";
import ClassicExtension from "./ClassicExtension";

/**
 * Call given signal handler     [data-snippet-interval-refresh-url]
 * with interval in milliseconds [data-snippet-interval-refresh-interval]
 */
export default class SnippetIntervalRefreshExtension extends ClassicExtension {

    private naja: Naja;
    private intervals = new Map<string, number>();
    private queue = [];
    private processing = false;

    initialize(naja: Naja) {
        this.naja = naja;
        super.initialize(naja);
    }

    protected init($root: JQuery) {
        $root.find('[data-snippet-interval-refresh-url]').each((i, el) => {
            const $el = $(el);
            const url = $el.data('snippet-interval-refresh-url');
            if (!url) {
                return;
            }

            if (this.intervals.has(url)) {
                clearInterval( this.intervals.get(url) );
                this.intervals.delete(url);
            }

            const timeout = $el.data('snippet-interval-refresh-interval') ?? 30_000;
            const interval = setInterval(() => {
                this.queue.push(url);
                if (!this.processing) {
                    this.process();
                }
            }, timeout);
            this.intervals.set(url, <number><unknown>interval);
        });
    }

    private process() {
        this.processing = true;
        const url = this.queue.shift();
        if (!url) {
            this.processing = false;
            return;
        }
        this.naja.makeRequest('get', url, {unique: true})
            .finally(() => this.process());
    }

}