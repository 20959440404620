import IComponent from './IComponent';
import { Naja } from 'naja';
import IExtension from '../extensions/IExtension';

export type ComponentsType = {[componentName: string]: new ($component: JQuery<HTMLElement>, context?: any) => IComponent};

// This factory is naja extension
export class ComponentFactory implements IExtension {

    private readonly context: {naja?: Naja} = {};

    constructor (
        private readonly components: ComponentsType
    ) {
        this.components = components;
    }

    initialize (naja: Naja): void {
        this.context.naja = naja;

        if (process.env.NODE_ENV === 'development') {
            console.log(`Registered components (${Object.keys(this.components).length})`, this.components);
        }

        this.initComponents($('body'));
        naja.snippetHandler.addEventListener('afterUpdate', event => {
            this.initComponents($(event.detail.snippet));
            if (event.detail.snippet.id === 'snippet-discussion-newComment') {
                // refresh OpenAI - if exists
                if (this.components.OpenAI) {
                    this.initComponent($('[data-type="minutes-ai"]'));
                }
            }
        });
    }

    private initComponents ($root: JQuery<HTMLElement|Element>) {
        const $components = $root.find('[data-component]');
        if ($root.is('[data-component]')) {
            $components.add(<JQuery<HTMLElement>>$root);
        }
        $.each($components, (i, componentEl) => {
            this.initComponent(componentEl);
        });
    }

    private initComponent (componentEl) {
        const $component = $(componentEl);
        const name = $component.data('component');

        if (name && this.components[name]) {
            // JS component was found, try to initialize, skip on error
            try {
                (new this.components[name]($component, this.context)).init();
                ComponentFactory.success(name, componentEl);
                $component.removeAttr('data-component');
            } catch (err) {
                console.error(`Component "${name}" failed to initialize due error. Skipped.\n`, err);
            }
        } else {
            // JS component is missing
            ComponentFactory.warn(name, componentEl);
        }
    }

    private static warn (name, componentEl): void {
        if (process.env.NODE_ENV !== 'production') {
            console.warn(`Component '${name}' does not exist. Did you register the component for the element 👇 ?`);
            console.log(componentEl);
        }
    }

    private static success (name, componentEl): void {
        if (process.env.NODE_ENV !== 'production') {
            console.log(`✅ ${name}`, componentEl);
        }
    }
}
