import { Naja } from "naja";
import IExtension from "./IExtension";

export default class ScrollToAnchorExtension implements IExtension {
    initialize(naja: Naja): void {
        naja.addEventListener('init', () => {
            const hash = window.location.hash.replace('#','');
            const element = document.getElementById(hash);
            if (!element) {
                return;
            }

            element.scrollIntoView({block: "center"});
            element.classList.add('border');
            element.classList.add('border-primary');
        });
    }
}
