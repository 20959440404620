import IExtension from "./IExtension";
import {Naja} from "naja";

/**
 * Replace default "enter to submit" form behavior with simulated click on submit button
 * It help to handle all js events attached to submit button
 *
 * TODO: Do we really need this?
 */
export default class SubmitFormOnEnterKeyExtension implements IExtension {
    initialize(naja: Naja): void {
        naja.addEventListener('init', () => this.init());
    }

    private init(): void {
        $(document).on("keypress", 'form', e => {
            if (e.key === 'Enter'
                && !$(e.target).is('textarea')
                && !$(e.target).is('.ck')
            ) {
                const $primarySubmit = $(e.currentTarget).find('input[type=submit].btn-primary');
                if ($primarySubmit.length === 1) {
                    e.preventDefault();
                    e.stopPropagation();
                    $primarySubmit.trigger('click');
                }
            }
        });
    }
}