import ComponentManager from 'nms-dashboard-js/src/ComponentManager';
import DashboardComponent from 'nms-dashboard-js/src/DashboardComponent';
import LineChartTemplate from './LineChart.ejs';
import Chart from 'chart.js';

export default class LineChart extends DashboardComponent
{
	createChart()
	{
		const ctx = this._$element.find('canvas')[0].getContext('2d');
		const options = {
			maintainAspectRatio: false,
			legend: {
				display: false
			},
			tooltips: {
				callbacks: {
					label: function(tooltipItem, data) {
						return tooltipItem.yLabel + '%';
					}
				}
			},
			scales: {
				xAxes: [{
					gridLines: {
						display: false
					},
					scaleLabel: {
						fontColor: '#8F8F8F',
						fontSize: 10
					},
                    ticks: {
                        autoSkip: false
                    }
				}],
				yAxes: [{
					ticks: {
						padding: 10,
						suggestedMax: 100,
						suggestedMin: 0,
						stepSize: 25,
						callback: function(value, index, values) {
							return value + '%';
						}
					},
					scaleLabel: {
						fontColor: '#8F8F8F',
						fontSize: 10
					}
				}]
			}
		};

		this.decorateData();

		this._chart = new Chart(ctx, {
			type: 'line',
			data: this._data.data,
			options: options
		});

		return this._chart;
	}

	onUpdate(data)
	{
		if (!this._chart) {
			this.createChart();
		} else {
			this.decorateData();
			this._chart.data.labels = this._data.data.labels;
			this._chart.data.datasets[0] = this._data.data.datasets[0];
			this._chart.update();
		}

		this.updateSnippets();
	}

	decorateData()
	{
		if (!this._data) return;

		const dataset = this._data.data.datasets[0];
		dataset.borderColor = this._options.color;
		dataset.pointRadius = 5;
		dataset.pointBackgroundColor = this._options.color;
		dataset.fill = false;
		dataset.lineTension = 0;
		dataset.spanGaps = true;
	}

	render()
	{
		const template = this.getTemplate();
		template.setFile(LineChartTemplate);

		return template.render();
	}
}

ComponentManager.registerComponentClass('LineChart', LineChart);