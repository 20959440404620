import ClassicExtension from "./ClassicExtension";

export default class FileUploadExtension extends ClassicExtension {

    protected init($root): void {
        $root.find(".upload-file").each((i, input) => {
           $(input).on('change', (e) => {
                this.loadImage(e.currentTarget);
           });
        });
    }

    private loadImage(input: HTMLInputElement) {
        const preview = <HTMLImageElement>$(input).parent().children('.file-image')[0];
        if (!preview) {
            return;
        }

        const reader = new FileReader();

        reader.readAsDataURL(input.files[0]);
        reader.onload = () => {
            preview.src = ''+reader.result;
        };
    }


}
