import IExtension from "./IExtension";
import {Naja} from "naja";

/**
 * Prevents double submit selected forms and every ajax form
 */
export default class PreventDoubleSubmitExtension implements IExtension {

    /**
     * Disabled elements waiting on request completed to be enabled
     */
    private readonly disabledElements: JQuery[] = [];

    initialize(naja: Naja) {
        naja.addEventListener('init', () => this.nonAjaxInteraction($('body')));
        naja.snippetHandler.addEventListener('afterUpdate', e => this.nonAjaxInteraction( $(<HTMLElement>e.detail.snippet) ));

        naja.uiHandler.addEventListener('interaction', e=> this.ajaxInteraction( $(<HTMLElement>e.detail.element) ));
        naja.addEventListener('complete', () => this.enableDisabledElements());
    }

    /**
     * For desired effect button must includes attribute [data-prevent-double-submit]
     * Disables button when form is submitted. Button will not be enabled - expecting full page reload.
     */
    private nonAjaxInteraction($root: JQuery): void {
        $root.find('input[type="submit"][data-prevent-double-submit], button[type="submit"][data-prevent-double-submit]')
            .each((i, input) => {
                const $input = $(input);
                const form = <HTMLFormElement>$input.closest('form')[0];

                form?.addEventListener('submit', e => {
                    if (e.defaultPrevented === false) {
                        input.classList.add('disabled');
                    }
                })
            })
        ;
    }

    /**
     * Disable interacted input[type="submit"] when it fires AJAX request
     */
    private ajaxInteraction($interactedElement: JQuery): void {
        if ($interactedElement.is('input[type="submit"]')) {
            $interactedElement.attr('disabled', 1);
            this.disabledElements.push($interactedElement);
        }
    }

    private enableDisabledElements(): void {
        while (this.disabledElements.length > 0) {
            this.disabledElements.pop().prop('disabled', false);
        }
    }

}
