import IExtension from "./IExtension";
import {Naja} from "naja";

export default class TabsExtension implements IExtension {

    public initialize(naja: Naja): void {
        naja.addEventListener('init', () => this.init($('body')));
        naja.snippetHandler.addEventListener('afterUpdate', (e) => this.init($(e.detail.snippet)));
    }

    private init($root): void {
        $root.find("ul.tabs li a").not('.event-binded').on('click', e => {
            const $el = $(e.currentTarget);
            const $tabsId = $el.parent().parent().attr("id"); /* Get Tabs group name */
            const $tabId = $el.attr("href").replace("#",""); /* Get particular tab ID */

            $el.parent().parent().children("li").removeClass("is-active"); /* Make all tabs unselected */
            $el.parent("li").addClass("is-active"); /* Make the clicked tab selected */

            const $targetDivContainterDivs = $root.find("div[data-tabs-content="+$tabsId+"] .tabs-panel"); /* All divs in the tab group */
            const $targetDiv = $root.find("div[data-tabs-content="+$tabsId+"] .tabs-panel#" + $tabId); /* The div corresponding to the clicked tab */

            $targetDivContainterDivs.removeClass("is-active");
            $targetDiv.addClass("is-active");

            e.stopPropagation();
            e.preventDefault();

        }).addClass('event-binded');
    }
}
