import BaseComponent from '../../BaseComponent';

import 'grapesjs/dist/css/grapes.min.css';
import grapesjs from 'grapesjs';
import grapesJsMjml from 'grapesjs-mjml';

export default class EmailEditor extends BaseComponent {
    public editor: HTMLDivElement;
    public grapesjsEditor: grapesjs.Editor;
    public init (): void {
        this.initEditor();
    }

    private initEditor () {
        const editorArea = <HTMLTextAreaElement> this.$component[0];

        this.editor = document.createElement('div');
        this.editor.setAttribute('class', editorArea.getAttribute('class'));
        this.editor.setAttribute('id', editorArea.getAttribute('id') + '-editor');
        this.editor.setAttribute('data-language', editorArea.getAttribute('data-language'));
        this.editor.innerHTML = <string> editorArea.value;
        editorArea.parentNode.append(this.editor);
        editorArea.style.display = 'none';

        this.grapesjsEditor = grapesjs.init({
            container: this.editor,
            fromElement: true,
            plugins: [grapesJsMjml],
            storageManager: false,
            pluginsOpts: {
                columnsPadding: 0
            },
            parser: {
                textTags: ['br', 'b', 'i', 'u', 'a', 'ul', 'ol', 'span'],
            }
        });

        this.grapesjsEditor.on('update', () => {
            editorArea.value = this.grapesjsEditor.getHtml();
        });
    }
}
