import m, {Vnode} from "mithril";
import Chatbot from "../Chatbot";
import TMessage from "../model/TMessage";
import decodeHTMLEntities from "../../../../helpers/decodeHtmlEntities";
import htmlSubstring from "../../../../helpers/htmlSubstring";
import TAttachment from "../model/TAttachment";

export default class Messages {

    constructor(
        private readonly chatbot: Chatbot
    ) { }


    private assistantMessage(message: TMessage, animate: boolean): Vnode {
        return m('.chatbot-message.d-flex.flex-row.flex-nowrap.gap-2.mb-3'+(animate?'.chatbot-animate-message':''), {}, [
            m('.chatbot-avatar.avatar', {
                title: 'Tina',
            }),
            m('.message.d-flex.flex-column.flex-grow-1.align-items-start.gap-1.p-2.position-relative', {}, [
                this.assistantMessageContent(message),
                message.completed ? this.copyButton(message) : null,
                (message.completed && message.attachments?.length) ? this.assistantMessageAttachments(message.attachments) : null,
            ]),
        ]);
    }

    private assistantMessageContent(message: TMessage): Vnode {
        if (message.content === '...') {
            return m('i.opacity-50', {}, this.chatbot.translator.translate('thinking'));
        }

        return m.trust(message.content);
    }

    private assistantMessageAttachments(attachments: TAttachment[]): Vnode {
        const images = attachments.filter(a => a.type === 'image');
        const others = attachments.filter(a => a.type !== 'image');

        return m('.message-attachments.w-100.mt-1.border-top', {}, [
            images.length ? m('.images.d-flex.flex-wrap.gap-2.mt-2', {},
                images.map(a => m('img.cursor-pointer.border', {
                    alt: a.fileName,
                    title: a.fileName,
                    src: this.chatbot.api.getDownloadFileUrl(a.uuid, true),
                    onclick: () => this.chatbot.state.imageDetailUrl = this.chatbot.api.getDownloadFileUrl(a.uuid, false),
                })),
            ) : null,
            others.length ? m('.others', {},
                others.map(a => {
                    const extension = a.fileName.toLowerCase().split('.').pop();
                    const icon = {
                        pdf: 'file-earmark-pdf',
                        doc: 'file-earmark-word',
                        docx: 'file-earmark-word',
                        xls: 'file-earmark-excel',
                        xlsx: 'file-earmark-excel',
                    }[extension] ?? 'file-earmark-text';

                    return m('.others.d-flex', {}, [
                        m(`i.bi.bi-${icon}.p-2`),
                        m('a.p-2', {
                            href: this.chatbot.api.getDownloadFileUrl(a.uuid, false),
                            download: true,
                        }, a.fileName),
                    ]);
                })
            ) : null,
        ]);
    }

    private userMessage(message: TMessage, animate: boolean): Vnode {
        return m('.user-message.d-flex.flex-row-reverse.flex-nowrap.align-self-end.gap-2.mb-3'+(animate?'.chatbot-animate-message':''), {}, [
            m('img.avatar.d-none.d-md-block', {
                src: this.chatbot.state.avatarPath,
                title: this.chatbot.state.nick,
                alt: this.chatbot.translator.translate('avatar'),
            }),
            m('.message.d-flex.flex-column.flex-grow-1.gap-1.p-2', {}, [
                m('.', m.trust(message.content)),
            ])
        ]);
    }

    private copyButton(message: TMessage): Vnode {
        return m('button.btn.btn-sm.btn-flat-primary', {
            title: this.chatbot.translator.translate('copy'),
            onclick: (e: MouseEvent) => {
                e.preventDefault();

                const html = message.content;
                const plain = decodeHTMLEntities(html.replaceAll('\n', '')
                    .replaceAll('<li>', '- ')
                    .replace(/<\/p><\/h3>|<\/h3>|<\/p><\/li>|<\/p>|<\/li>|<\/ol>/ig, '\n')
                    .replace(/(<([^>]+)>)/ig, ''));

                const clipboardItem = new ClipboardItem({
                    'text/html': new Blob([html], { type: 'text/html' }),
                    'text/plain': new Blob([plain], { type: 'text/plain' })
                });

                navigator.clipboard.write([clipboardItem])
                    .then(() => console.log("clipboard.write() Ok"))
                    .catch(error => alert(error))

            },
        }, m('i.bi.bi-copy'));
    }

    private chatbotThink(): Vnode {
        return m('.chatbot-think', {}, Array(3).fill(m('span', '.')));
    }

    /**
     * Get message with animated substring and keep animation go brrrr
     * When animation should not run, it returns null
     */
    private getAnimatedMessage(): TMessage|null {
        if (this.chatbot.state.animateFirstMessageText) {
            const length = Math.round((Date.now() - this.chatbot.state.started) / 20); // 1 symbol every n ms
            const firstMessage = this.chatbot.state.messages[0];
            if (length > firstMessage.content.length) {
                this.chatbot.state.animateFirstMessageText = false;
                return null;
            }

            setTimeout(() => m.redraw(), 32);
            console.log(htmlSubstring(firstMessage.content, length));

            return {
                ...firstMessage,
                content: htmlSubstring(firstMessage.content, length),
                completed: false,
            };
        }

        return null;
    }

    view() {
        const animatedMessage = this.getAnimatedMessage();
        if (animatedMessage) {
            return m('.chatbot-scroll-wrapper.w-100.d-flex.flex-column.flex-grow-1',{}, [
                m(`.chatbot-conversation-wrapper.d-flex.flex-column.flex-grow-1.w-100`, {}, [
                    this.assistantMessage(animatedMessage, false),
                ]),
            ]);
        }

        return m('.chatbot-scroll-wrapper.w-100.d-flex.flex-column.flex-grow-1', {
            onupdate: vnode => {
                if (this.chatbot.state.autoScroll) {
                    vnode.dom.scrollTop = vnode.dom.scrollHeight;
                }
            },
            onscroll: e => {
                this.chatbot.state.autoScroll = e.currentTarget.scrollTop >= e.currentTarget.scrollHeight-e.currentTarget.clientHeight;
            },
        }, [
            m('.chatbot-conversation-wrapper.d-flex.flex-column.flex-grow-1.w-100', {}, [
                ...this.chatbot.state.messages.map((message,i) => {
                    if (message.role === 'assistant') {
                        return this.assistantMessage(message, this.chatbot.state.animateMessages && i>0);
                    }
                    return this.userMessage(message, this.chatbot.state.animateMessages && i>0)
                }),
                this.chatbot.state.sending ? this.chatbotThink() : null,
            ]),
        ]);
    }
}
