import BaseComponent from "../../BaseComponent";

// Project setup - Activation step
export default class ActivationStep extends BaseComponent {
    public init(): void {
        this.$component.find('[data-activation-status]').each((i, input) => {
            const $activationStatusField = $(input);
            const $activationStep = $activationStatusField.closest('[data-activation-step]');
            $activationStatusField.on('change', () => {
                $activationStep.removeAttr('class');
                $activationStep.addClass('project-status-'+$activationStatusField.val())
            }).trigger('change');
        });

        this.$component.find('button[name="_submit"]').on('click', e => { // for [data-activation-step] button[name="_submit"]
            if (this.$component.find('[data-activation-status]').first().val() === 'deleted') {
                const message = $(e.currentTarget).closest('[data-activation-step]').data('confirm-delete-message');
                return confirm(message);
            }
        });
    }

}