import ClassicExtension from "./ClassicExtension";

export default class ModalExtension extends ClassicExtension {

    protected init($root: JQuery): void {
        $root.find('[data-toggle="modal"]').each( (i,el) => {
            el.addEventListener('click', e => {
                e.preventDefault();
                const target = el.dataset.target;
                if (el.dataset.target) {
                    $(target).modal('show');
                }
            });
        });

        // update modal positioning
        $root.find('.modal[data-bs-reposition]').each(function(index, item) {
            if ($($(item).data('bs-reposition')).length > 0) {
                $(item).appendTo($(item).data('bs-reposition'));
            }
        });
    }
}
