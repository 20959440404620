import ComponentManager from 'nms-dashboard-js/src/ComponentManager';
import DashboardComponent from 'nms-dashboard-js/src/DashboardComponent';
import DoughnutChartTemplate from './DoughnutChart.ejs';
import Chart from 'chart.js';

export default class DoughnutChart extends DashboardComponent
{
	createChart()
	{
		const ctx = this._$element.find('canvas')[0].getContext('2d');
		const options = {
			cutoutPercentage: 70,
			maintainAspectRatio: false,
			legend: {
				position: 'bottom',
				labels: {
					usePointStyle: true
				}
			}
		};

		this.decorateData();

		this._chart = new Chart(ctx, {
			type: 'doughnut',
			data: this._data.data,
			options: options
		});

		return this._chart;
	}

	onUpdate(data)
	{
		if (!this._chart) {
			this.createChart();
		} else {
			this.decorateData();
			this._chart.data.labels = this._data.data.labels;
			this._chart.data.datasets[0] = this._data.data.datasets[0];
			this._chart.update();
		}

		this.updateSnippets();
	}

	decorateData()
	{
		if (!this._data) return;

		const dataset = this._data.data.datasets[0];

		const colors = ['#DDC4DA', '#C79CC2', '#8F3985', '#64285D'];
		dataset.backgroundColor = colors;
		dataset.hoverColor = colors;
		dataset.borderWidth = 0;
	}

	render()
	{
		const template = this.getTemplate();
		template.setFile(DoughnutChartTemplate);

		return template.render();
	}
}

ComponentManager.registerComponentClass('DoughnutChart', DoughnutChart);