import BaseComponent from '../../BaseComponent';
type ReCaptcha = ReCaptchaV2.ReCaptcha;

export default class Recaptcha extends BaseComponent {
    public init (): void {
        // const clientIDs = {};
        // const self = this;
        let init = false;

        const self = this;

        $.getScript('https://www.google.com/recaptcha/api.js?render=explicit', function () {
            window.Nette.recaptcha = function (grecaptcha: ReCaptcha) {
                const items = self.$component[0].getElementsByClassName('g-recaptcha');
                const length = items.length;

                if (length === 0) {
                    return;
                }

                grecaptcha.ready(function () {
                    let resolved = false;
                    const submitListenerFactory = function (form) {
                        return function (e) {
                            // we already have reCaptcha response, or the form is invalid - or submission is prevented for some other, unknown reason
                            if (resolved || e.defaultPrevented) {
                                return;
                            }

                            e.preventDefault();

                            grecaptcha.execute().then(function (token) {
                                resolved = true;

                                // reCaptcha token expires after 2 minutes; make it 5 seconds earlier just in case network is slow
                                setTimeout(function () {
                                    resolved = false;
                                }, (2 * 60 - 5) * 1000);

                                const inputs = self.$component[0].getElementsByClassName('g-recaptcha-response');
                                for (let i = 0; i < inputs.length; i++) {
                                    inputs[i].value = token;
                                }

                                form.submit();
                            });
                        };
                    };

                    let form;
                    for (let i = 0; i < length; i++) {
                        grecaptcha.render(items[i]);

                        form = items[i].closest('form');
                        form.addEventListener('submit', submitListenerFactory(form));
                    }
                });
                init = true;
            };

            if (!init) {
                window.Nette.recaptcha(window.grecaptcha);
            }
        });
    }
}
