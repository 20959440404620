import ClassicExtension from "./ClassicExtension";

export default class RevealDiscussionExtension extends ClassicExtension {

    protected init($root): void {
        /* New Mod Post Date Field Reveal */
        $root.find(".entry-date.pre-entry a").not('.event-binded').on('click', function(e) {
            $root.find(".entry-date.hidden").removeClass("hidden");
            $(this).parent().hide();

            e.stopPropagation();
            e.preventDefault();
        }).addClass('event-binded');

        /* New Post Attachment Upload */
        $root.find("[data-show-upload-files]").not('.event-binded').on('click', function(e) {
            $("[data-upload-files-section]").first().show();
        }).addClass('event-binded');

        /* New Post Pin Options Reveal */
        $root.find(".new-post .show-pin-options").not('.event-binded').on('click', function(e) {
            $root.find(".new-post .post-pin-options").show();
            $(this).hide();

            e.stopPropagation();
            e.preventDefault();
        }).addClass('event-binded');
    }
}
