import BaseComponent from "../../BaseComponent";
import ImageReposition from "../../ImageRating/js/ImageReposition";

export default class Diary extends BaseComponent {

    init(): void {
        const slideOptions = {
            start: () => this.$component.find('.viewport.leaflet-container').hide(),
            done: () => ImageReposition.repositionImageRating(),
        };

    }

}
