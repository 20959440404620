import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'beautifymarker/leaflet-beautify-marker-icon';
import 'beautifymarker/leaflet-beautify-marker-icon.css';
import 'beautifymarker';

$.widget("nms.imgRating", $.wgm.imgViewer2, {
	options: {
		onReady: function() {
			this.map.scrollWheelZoom.disable();
			this.options.afterInit.call(this);
		},
		afterInit: $.noop,
		beforeIconCreated: $.noop,
		beforeMarkerAdded: $.noop,
		onMarkerAdded: $.noop,
		onImport: $.noop,
		icon: {
			icon: 'circle',
			iconShape: 'marker',
			borderWidth: 1,
			borderColor: '#23426f',
			backgroundColor: '#337ab7',
			textColor: '#23426f'
		},
        viewDestination: undefined
	},
	addMarker: function(data) {
		const map = this.map;
		const loc = this.relposToLatLng(data.x, data.y);
		const iconOptions = $.extend(this.options.icon);

		this.options.beforeIconCreated.call(this, iconOptions, data);

		const icon = L.BeautifyIcon.icon(iconOptions);
		const marker = L.marker(loc, {icon: icon});

		this.options.beforeMarkerAdded.call(this, marker, data);

		marker.addTo(map);

		if (this.markers === undefined) {
			this.markers = [];
		}
		this.markers.push(marker);

		this.options.onMarkerAdded.call(this, marker, data);

		return marker;
	},
	import: function(data) {
		if (!this.ready) return;

		const map = this.map;

		let remove = false;
		map.eachLayer(function(layer) {
			if (remove) {
				map.removeLayer(layer);
			} else {
				remove = true;
			}
		});

		for (let item of data) {
			this.addMarker.call(this, item);
		}

		this.options.onImport.call(this, data);
	},
	clear: function() {
        if (this.markers) {
            for (let marker of this.markers) {
                marker.remove();
            }
        }
		this.markers = [];
	}
});
