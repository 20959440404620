import Sortable from 'sortablejs';
import BaseProjectForm from "./BaseProjectForm";

export default class ProjectFormQuestionary extends BaseProjectForm
{
    public registerEvents() {
        // https://github.com/RubaXa/Sortable
        Sortable.create( this.$component.get(0), {
            draggable: "[data-question-id]",
            handle: "[data-handle]",
            dataIdAttr: "data-question-id",
            store: {
                get: () => [],
                set: (sortable) => {
                    $.ajax({
                        url: this.$component.data('change-order-link'),
                        method: 'POST',
                        data: { order: sortable.toArray() },
                    });
                }
            }
        });
    }

}
