import BaseComponent from "../../BaseComponent";

export default class SelectContext extends BaseComponent {
    init(): void {
        const $questionTextarea = <JQuery<HTMLTextAreaElement>>this.$component.find('textarea[name="question"]');
        const $send = this.$component.find('button[name="send"]');
        const $select = this.$component.find('select[name="context"]');

        // Enable/disable textarea and send button by select value
        $select.on('change', () => {
            $questionTextarea.prop('disabled', !$select.val());
            $send.prop('disabled', !$select.val());
        });
        $select.trigger('change');

        // Change height of textarea by its content
        $questionTextarea.on('input', () => {
            $questionTextarea[0].style.height = '5px';
            $questionTextarea[0].style.height = $questionTextarea[0].scrollHeight + 'px';

            $send.prop('disabled', !String($questionTextarea.val()).trim());
        });
        $questionTextarea.trigger('input');

        // Send question by hitting Enter, create new line with Shift+Enter
        $questionTextarea.on('keydown', e => {
            if (e.key === 'Enter' && !e.shiftKey && String($questionTextarea.val()).trim()) {
                e.preventDefault();
                $questionTextarea[0].form.submit();
            }
        });

        // Disallow send form by send button when textarea is empty
        $send.closest('form').on('submit', e => {
            // @ts-ignore
            if ($(e.originalEvent.submitter).is('button[name="send"]') && !String($questionTextarea.val()).trim()) {
                e.preventDefault();
                $questionTextarea.focus();
            }
        })



    }
}