import BaseComponent from "../../BaseComponent";

export default class GeneralInfoForm extends BaseComponent {
    public init(): void {
        this.$component.find('[data-action-duplicate_project]')
            .on('change', (e) => {
                const url = $(e.currentTarget).data('action-url');
                $.ajax({
                    url: url,
                    data: {
                        projectId: $(e.currentTarget).val(),
                    },
                    success: (payload) => {
                        if (payload) {
                            this.$component.find("select[name='notifications_language']").val(payload.language);
                            this.$component.find("select[name='timezone']")[0].selectize.setValue(payload.timezone);
                            this.$component.find('.duplication_settings').show();
                        }
                    }
                });
            }
        );

        const notificationsLanguageElement = <JQuery<HTMLSelectElement>>this.$component.find("select[name='notifications_language']");
        if (notificationsLanguageElement && notificationsLanguageElement.val() === '') {
            const userLocale =
                navigator.languages && navigator.languages.length
                    ? navigator.languages[0]
                    : navigator.language;
            notificationsLanguageElement.find('option[value*="' + userLocale.replace(/^(.*)-(.*)?$/, '$1') + '"]').attr('selected', 'selected');
        }
        const timezoneElement = <JQuery<HTMLSelectElement>>this.$component.find("select[name='timezone']");
        if (Intl && timezoneElement && timezoneElement.val() === '') {
            timezoneElement.find('option[value*="' + Intl.DateTimeFormat().resolvedOptions().timeZone + '"]').attr('selected', 'selected');
        }
    }
}
