import IExtension from "./IExtension";
import {Naja} from "naja";

export default class InactiveLinksExtension implements IExtension {

    public initialize(naja: Naja): void {
        naja.snippetHandler.addEventListener('afterUpdate', (e) => this.init($(e.detail.snippet)));
    }

    private init($root): void {
        if ($root.hasClass('inactive-link')) {
            $root.on('click', (e) => {
                e.stopPropagation();
                e.preventDefault();
            });
            $root.addClass('event-binded');
        }
    }
}
