export default function(ctx) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }

 for (var i = 5; i >= 1; i--) { ;
__p += '\n	<div class="">\n		<div class="fs-4">\n			';
 for (var j = 1; j <= i; j++) { ;
__p += '\n				<span class="text-gold">&#9733;</span>\n			';
 } ;
__p += '\n			';
 for (var k = i+1; k <= 5; k++) { ;
__p += '\n				<span class="text-gray-400">&#9734;</span>\n			';
 } ;
__p += '\n		</div>\n		<div class="flex-grow-1" data-rating="' +
((__t = ( i )) == null ? '' : __t) +
'"></div>\n	</div>\n';
 } ;
__p += '\n';
return __p
}