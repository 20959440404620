import BaseProjectForm from "./BaseProjectForm";

export default class ProjectFormAttributes extends BaseProjectForm
{
    public registerEvents() {
        this.setupAttributes();
    }

    private setupAttributes() {

            const $type = this.$component.find('[data-attribute-type]');
            const $options = this.$component.find('[data-attribute-options]');
            const $visibility = this.$component.find('[data-visibility-checkbox]');

            if ( this.showOptions($type.val()) ) {
                $options.show();
            } else {
                $options.hide();
            }

            $type.on('change', (e: JQuery.ChangeEvent<HTMLElement, undefined, HTMLInputElement>) => {
                if ( this.showOptions(e.currentTarget.value) ) {
                    $options.slideDown();
                } else {
                    $options.slideUp();
                }
            }).trigger('change');

            $visibility.on('change', (e) => {
                const $checkbox = $(e.currentTarget);
                const visibilityString = this.getVisibilityString();

                if ($checkbox.data('visibility-checkbox') === 'R' && !$checkbox.is(':checked')) {
                    this.$component.find(`[data-visibility-checkbox='O']`).prop("checked", false);
                }
                if ($checkbox.data('visibility-checkbox') === 'O' && $checkbox.is(':checked')) {
                    this.$component.find(`[data-visibility-checkbox='R']`).prop("checked", true);
                }

                const $requiredAnswerInput = this.$component.find('input[name="required"]');
                if (this.getVisibilityString().includes('R') ) {
                    $requiredAnswerInput.closest('div').slideDown();
                } else {
                    $requiredAnswerInput.prop("checked", false).trigger('change');
                    $requiredAnswerInput.closest('div').slideUp();
                }

                this.$component.find('[data-visibility-hint-box]').hide();
                this.$component.find(`[data-visibility-hint-box='${visibilityString}']`).show();
            });

        this.$component.find(`[data-visibility-checkbox='C']`).trigger('change');

    }


	private getVisibilityString(): string {
        let visibilityString = "";
        this.$component.find('[data-visibility-checkbox]').each((i, item) => {
            const $item = $(item);
            if ($item.is(':checked')) {
                visibilityString += $item.data('visibility-checkbox');
            }
        });
        return visibilityString;
    }

    private showOptions(value): boolean {
        return ['scale', 'multi'].indexOf(''+value) !== -1;
    }

}
