import BaseComponent from "../../BaseComponent";

export default class RegistrationForm extends BaseComponent {
    public init (): void {

        this.$component.find('form').on('submit', (e) => {
            if(window.Nette.validateForm(e.currentTarget)) {
                $('#registration-confirm').hide().after('<div class="spinner"></div>');
            }
        });

        this.$component.find('#terms-of-use-link').on('click', (e) => {
            document.getElementById('terms-of-use').style.display = 'block';
        });

        this.$component.find('#terms-of-use').on('click', (e) => {
            document.getElementById('terms-of-use').style.display = 'none';
        });

        this.$component.find('#terms-of-use > *').on('click', e => e.stopPropagation());

        this.$component.find('[data-close-terms]').on('click', (e) => {
            document.getElementById('terms-of-use').style.display = 'none';
        })
    }
}
