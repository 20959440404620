import naja from 'naja';

(function($) {

    $.fn.pop = function() {
        var top = this.get(-1);
        this.splice(this.length-1,1);
        return top;
    };

    $.fn.shift = function() {
        var bottom = this.get(0);
        this.splice(0,1);
        return bottom;
    };

    /** Callback on enter + mod (key name e.g. ctrl) */
    $.fn.enterKey = function (fnc, mod) {
        return this.each(function () {
            $(this).keypress(function (ev) {
                var keycode = (ev.keyCode ? ev.keyCode : ev.which);
                if ((keycode == '13' || keycode == '10') && (!mod || ev[mod + 'Key'])) {
                    fnc.call(this, ev);
                }
            });
        });
    };


    /**
     * Depends on $.nette.ajax extension
     * @returns {*}
     */
    $.fn.inlineEdit = function () {
        var button;
        var loader;

        var send = function(input) {
            naja.makeRequest('POST', input.data('url'), {value: input.text()}, {history: false})
                .then(() => {
                    // todo: effect of this fragment is overridden by snippet redraw
                    loader.remove();
                    button.html('&nbsp;&check;');
                    button.addClass('success');
                    button.show();
                }).catch(() => {
                    loader.remove();
                    button.html('&nbsp;&#10539;');
                    button.addClass('error');
                    button.show();
                });
        };

        var showLoader = function(input) {
            loader = $('<span class="loader small"></span>');
            input.after(loader);
        };

        this.keypress(function(e) {
            if (e.which == 13) {
                var input = $(this).first();
                showLoader(input);
                send(input);
                return false;
            }
            return true;
        });
        this.click(function () {
            var input = $(this).first();
            button = input.next('.saveContentEditable');
            if (!button.length) {
                button = $('<span style="cursor: pointer;" class="saveContentEditable ajax"></span>');
                button.click(function () {
                    button.hide();
                    showLoader(input)
                    send(input);
                });
            }
            button.removeClass('error success');
            button.html('&nbsp;&#9998;');
            input.after(button);
        });
        return this;
    };

}(jQuery));
