export default function(ctx) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="form-group d-flex flex-row gap-1" data-poll-option-id="' +
((__t = (ctx.optionId)) == null ? '' : __t) +
'">\r\n    <input type="text" name="polls[' +
((__t = (ctx.pollId)) == null ? '' : __t) +
'][options][' +
((__t = (ctx.optionId)) == null ? '' : __t) +
'][text]" id="frm-discussionForm-form-polls-' +
((__t = (ctx.pollId)) == null ? '' : __t) +
'-options-' +
((__t = (ctx.optionId)) == null ? '' : __t) +
'-text" required data-nette-rules="[{&quot;op&quot;:&quot;:filled&quot;,&quot;msg&quot;:&quot;This field is required&quot;}]" class="form-control flex-grow-1">\r\n\r\n    <label class="upload-label" for="frm-discussionForm-form-polls-' +
((__t = (ctx.pollId)) == null ? '' : __t) +
'-options-' +
((__t = (ctx.optionId)) == null ? '' : __t) +
'-image">\r\n        ';
 if(ctx.image) { ;
__p += '\r\n        <img src="' +
((__t = (ctx.image)) == null ? '' : __t) +
'" class="file-image">\r\n        ';
 } ;
__p += '\r\n        <input class="upload-file" type="file" name="polls[' +
((__t = (ctx.pollId)) == null ? '' : __t) +
'][options][' +
((__t = (ctx.optionId)) == null ? '' : __t) +
'][image]" id="frm-discussionForm-form-polls-' +
((__t = (ctx.pollId)) == null ? '' : __t) +
'-options-' +
((__t = (ctx.optionId)) == null ? '' : __t) +
'-image" data-nette-rules="[{&quot;op&quot;:&quot;optional&quot;},{&quot;op&quot;:&quot;:image&quot;,&quot;msg&quot;:&quot;The uploaded file must be image in format JPEG, GIF or PNG.&quot;}]">\r\n        <span class="btn btn-outline-primary">\r\n                <i class="bi bi-image"></i>\r\n            </span>\r\n    </label>\r\n\r\n    <button type="button" value="" formnovalidate="" class="btn btn-outline-danger" data-remove-poll-option>\r\n        <i class="bi bi-trash-fill"></i>\r\n    </button>\r\n</div>\r\n';
return __p
}