import ClassicExtension from "./ClassicExtension";

export default class ProcessHiddenFormsExtension extends ClassicExtension {

    protected init($root): void {
        $root.find('.show-form').not('.event-binded').on('click', e => {
            this.processHiddenForm($(e.currentTarget));
        }).addClass('event-binded');

        $root.find('form.form-hidden').each((i, form) => this.processHiddenForm($(form)));
    }

    private processHiddenForm($el) {
        const formHidden = $el.data('form-hidden');
        if (formHidden !== undefined) { // btn
            $(formHidden).show();
            $(formHidden).children('input, textarea').trigger('focus');
        } else {
            // Form
            if (!$el.is('form')) {
                $el = $el.closest('form');
            }
            $($el.data('show-form')).show();
            $el.children('input, textarea').first().trigger('blur');
        }
    }



}
