import BaseComponent from "../../BaseComponent";

export default class ClientSelection extends BaseComponent
{
    public init(): void
	{
		const $selectionToggle = this.$component.find('[data-selection-toggle]');
		const $selectionControls = this.$component.find('[data-selection-controls]');
		const $clientSelection = this.$component.find('[data-client-selection]');
		const $reveal = $('#'+this.$component.find('[data-open]').data('open'));

        let checkAllCheckboxes = false;
		$clientSelection.find('[data-select-all]').on('click', e => {
			const $checkboxes = $clientSelection.find('[data-client-checkbox] input');
			$checkboxes.prop('checked', checkAllCheckboxes = !checkAllCheckboxes);
		});

		this.$component.find('[data-client-checkbox] input').on('change', (e) => {
			this.$component.find('[data-select-all-checkbox] input').prop('checked', false);
			if ($(e.currentTarget).is(':checked')) {
				this.$component.find('[data-select-new-checkbox] input').prop('checked', false);
			} else {
				this.$component.find('[data-select-new-checkbox] input').prop('checked', this.areAllNewUsersChecked());
			}
		});

		this.$component.find('[data-enable-selection-button]').on('click', (e) => {
			e.preventDefault();
			$selectionToggle.prop('checked', true).parent().hide();
			$selectionControls.show();
		});

		this.$component.find('[data-disable-selection-button]').on('click', (e) => {
			e.preventDefault();
			$selectionToggle.prop('checked', false).parent().show();
			$selectionControls.hide();
		});

		this.$component.find('[data-save-clients-button]').on('click', (e) => {
			e.preventDefault();
			this.removeOrAddClients();
		});

		this.$component.find('[data-remove-all-clients-button]').on('click', (e) => {
			e.preventDefault();
			this.removeClients(true);
		});

		this.$component.find('[data-open]').on('click', (e) => {
			e.preventDefault();
		});

		this.$component.find('[data-close]').on('click', (e) => {
			e.preventDefault();

			const $parentModal = this.$component.closest('[data-reveal]');

			if ($parentModal.length) {
				$parentModal.modal('show');
			}
		});

		this.$component.find('[data-add-by-ids-button]').on('click', (e) => {
			e.preventDefault();
			this.addClientsByIds();
		});

		this.$component.find('[data-select-new-checkbox]').on('click', (e) => {
			this.selectNewUsers(e);
		});


		this.resetSeparators();
		this.updateNoClientsMessage();
	}

	private getFilteredClientsSelectionIds(): string[] {
		const clients = [];

		this.$component.find('[data-client-list] input:checked').each((i, input: HTMLInputElement) => {
			clients.push(input.value);
		});

		return clients;
	}


	private removeOrAddClients(): void {
		this.$component.find('[data-selected-clients] input').prop('checked', false).parent().hide();
		const clientIds = this.getFilteredClientsSelectionIds();
		const $unselectedClients = this.$component.find('[data-selected-clients] input:not(:checked)');
		let counter = 0;

		$unselectedClients.each((i, input: HTMLInputElement) => {
			if (clientIds.indexOf(input.value) !== -1) {
				$(input).prop('checked', true).parent().show();
				counter++;
			}
		});

		const h4Element = this.$component.find('[data-selected-client-title]')[0];
		if (h4Element) {
			const h4ElementTitle = h4Element.getAttribute('data-selected-client-title');
			if (h4ElementTitle) {
				if (counter > 0) {
					h4Element.textContent = h4ElementTitle + ' (' + counter + ')' + ':';
				} else {
					h4Element.textContent = h4ElementTitle + ':';
				}
			}
		}

		this.resetSeparators();
		this.updateNoClientsMessage();
	}

	private addClients(ids = null): void {
		const clientIds = ids ? ids : this.getFilteredClientsSelectionIds();
		const $unselectedClients = this.$component.find('[data-selected-clients] input:not(:checked)');

		$unselectedClients.each((i, checkbox: HTMLInputElement) => {
			if (clientIds.includes(checkbox.value)) {
				$(checkbox).prop('checked', true).parent().show();
			}
		});
		this.resetSeparators();
		this.updateNoClientsMessage();
	}

	private addClientsByIds(): void {
		const $textarea = this.$component.find('[data-client-ids-textarea]');
		const value = (<string>$textarea.val()) || '';
		this.addClients(value.match(/[^\r\n\s,;a-z\-]+/g));
		$textarea.val('');
		this.updateNoClientsMessage();
	}

	private removeClients(removeAll = false): void {
		const clientIds = this.getFilteredClientsSelectionIds();
		const $selectedClients = this.$component.find('[data-selected-clients] input:checked')

		$selectedClients.each((i, checkbox: HTMLInputElement) => {
			if (removeAll || clientIds.includes(checkbox.value)) {
				$(checkbox).prop('checked', false)
					.parent()
					.hide();
			}
		});

		if (removeAll) {
			this.$component.find('[data-client-checkbox] input').prop('checked', false);
			this.$component.find('[data-select-new-checkbox] input').prop('checked', false);
		}
		this.resetSeparators();
		this.updateNoClientsMessage();
	}

	private resetSeparators(): void {
		const $clients = this.$component.find('[data-selected-clients] label');

		$clients.removeClass('last-selected');
		$clients.find('input:checked').last().parent().addClass('last-selected');
	}

	private areAllNewUsersChecked(): boolean {
		const ids = this.$component.find('[data-select-new-checkbox]').data('ids')?.split(",") || [];
		const checked = ids.filter((id) => $('[data-client-checkbox] input[value="'+id+'"]').is(':checked'));
		return checked.length === ids.length
	}

	private selectNewUsers(e): void {
		let ids = $(e).data('ids').toString().split(",");
		const check = $(e).children('input').is(':checked');
		ids.forEach((id) => {
			$('[data-client-checkbox] input[value="'+id+'"]').prop('checked', check);
		});
	}

	private updateNoClientsMessage(): void {
		const $message = this.$component.find('[data-no-clients-selected-message]')
		if (this.$component.find('[data-selected-clients] input:checked').length) {
			$message.hide()
		} else {
			$message.removeClass('info-box').addClass('error-box');
			$message.show();
		}

	}
}
