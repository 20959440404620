import Sortable from 'sortablejs';
import BaseComponent from "../../BaseComponent";
import BaseProjectForm from "./BaseProjectForm";

export default class ProjectFormTouchpoints extends BaseProjectForm
{
    private $parameterSharing: JQuery<HTMLElement>;
    private $touchpointsList: JQuery<HTMLElement>;

    public registerEvents() {
        this.setupTouchpoints();
    }

    private setupTouchpoints(): void {
        this.$parameterSharing = this.$component.find('[data-parameter-item]');
        this.$touchpointsList = this.$component.find('[data-parameter-touchpoints]');

        // attach events
        this.$parameterSharing.find('input').on('change', () => this.handleParameterSharingChange());

        // init
        this.handleParameterSharingChange(0);
    }

    /**
     * Is parameter restricted for list of touchpoints?
     * 1 => restricted
     * 0 => shared with all touchpoints
     */
    private isParameterRestricted(): boolean {
        const value = this.$parameterSharing.find('input:checked').val();
        return Number(value) === 1;
    }

    private handleParameterSharingChange(duration = undefined): void {
        const parameterIsRestricted = this.isParameterRestricted();

        if (parameterIsRestricted) {
            this.$touchpointsList.find('select').attr('readonly', 1);
            this.$touchpointsList.slideUp(duration);
            console.log('hide');
        } else {
            this.$touchpointsList.find('select').removeAttr('readonly');
            this.$touchpointsList.slideDown(duration);
            console.log('show');
        }
    }

}
