import IExtension from "./IExtension";
import {Naja} from "naja";

export default class CloseModalsExtension implements IExtension {
    initialize(naja: Naja): void {
        naja.addEventListener("complete", e => {
            this.closeModals(e.detail.payload?.closeModals || {});
        });
    }

    private closeModals(modals) {
        for (var key in modals) {
            var modalId = modals[key];
            $('#' + modalId).modal('hide');
        }
    }
}