import BaseComponent from "../../BaseComponent";

export default class RespondentSelection extends BaseComponent
{
    private previousAttributeOptions = [];
    private loadRespondentsSignalUrl;

	public init(): void {
        this.previousAttributeOptions = [];
        this.loadRespondentsSignalUrl = this.options?.loadRespondentsSignal;
        if (!this.loadRespondentsSignalUrl) {
            throw Error("RespondentSelection missing data-options loadRespondents.");
        }

		const $selectionToggle = this.$component.find('[data-selection-toggle]');
		const $selectionControls = this.$component.find('[data-selection-controls]');
		const $attributeOptions = this.$component.find('select[data-attribute-option-select]');
		const $respondentSelection = this.$component.find('[data-respondent-selection]');

		$attributeOptions.on('change', (e) => {
			const attributeOptions = [];

			$attributeOptions.each((i, select) => {
				const value = $(select).val();

				if (value && value !== '') {
					attributeOptions.push(value);
				}
			});

			if (this.shouldRedrawRespondents(attributeOptions)) {
				$.ajax({
					method: 'get',
					url: this.getSignalLink(attributeOptions)
				}).done(payload => {
					const data = payload.respondentSelection;

					this.createCheckboxes(data.filteredRespondents);
				});
			}

			this.previousAttributeOptions = attributeOptions;
		});

        let checkAllCheckboxes = false;
		$respondentSelection.find('[data-select-all]').on('click', e => {
			const $checkboxes = $respondentSelection.find('[data-respondent-checkbox] input')
				.filter(function() {
				return $(this).closest('div').css('display') !== 'none';
			});
			$checkboxes.prop('checked', checkAllCheckboxes = !checkAllCheckboxes);
		});

		this.$component.find('[data-respondent-checkbox] input').on('change', (e: JQuery.ChangeEvent<any, undefined, HTMLInputElement, any>) => {
			this.$component.find('[data-select-all-checkbox] input').prop('checked', false);
			if (e.currentTarget.checked) {
				this.$component.find('[data-select-new-checkbox] input').prop('checked', false);
			} else {
				this.$component.find('[data-select-new-checkbox] input').prop('checked', this.areAllNewUsersChecked());
			}
		});

		this.$component.find('[data-enable-selection-button]').on('click', (e) => {
			e.preventDefault();
			$selectionToggle.prop('checked', true).parent().hide();
			$selectionControls.show();
		});

		this.$component.find('[data-disable-selection-button]').on('click', (e) => {
			e.preventDefault();
			$selectionToggle.prop('checked', false).parent().show();
			$selectionControls.hide();
		});

		this.$component.find('[data-add-respondents-button]').on('click', (e) => {
			e.preventDefault();
			this.addRespondents();
		});

		this.$component.find('[data-save-respondents-button]').on('click', (e) => {
			e.preventDefault();
			this.removeOrAddRespondents();
		});

		this.$component.find('[data-remove-respondents-button]').on('click', (e) => {
			e.preventDefault();
			this.removeRespondents();
		});

		this.$component.find('[data-remove-all-respondents-button]').on('click', (e) => {
			e.preventDefault();
			this.removeAllRespondents();
		});

		this.$component.find('[data-open]').on('click', (e) => {
			e.preventDefault();
		});

		this.$component.find('[data-close]').on('click', (e) => {
			e.preventDefault();

			const $parentModal = this.$component.closest('[data-reveal]');

			if ($parentModal.length) {
				$parentModal.modal('show');
			}
		});

		this.$component.find('[data-add-by-ids-button]').on('click', (e) => {
			e.preventDefault();
			this.addRespondentsByIds();
		});

		this.$component.find('[data-select-new-checkbox]').on('click', (e) => {
            this.selectNewUsers(e.currentTarget);
		});


		this.resetSeparators();

        (function hookEvents(){
            function checkRelated(select) {
                const selected = select.find(':selected');//$('#allAttributeValues select option:selected');
                $('#respondentList input').prop('checked', false);
                if (selected.lenght && selected.data('users') !== undefined) {
                    selected.data('users').forEach(function (id) {
                        $('#respondentList input[value="' + id +'"]').prop('checked', true);
                    });
                }
            }
            const attributeValueSelect = '#allAttributeValues select';
            $('#respondentFilter select').not(attributeValueSelect).on('change', function () {
                $(attributeValueSelect).hide();
                $(attributeValueSelect + '[data-attribute="' + $(this).val() + '"]').show().trigger('change');
            });
            $(attributeValueSelect).on('change', function () {
                checkRelated($(this));
            });
        })();

		this.updateNoRespondentsMessage();
	}

	private shouldRedrawRespondents(attributeOptions): boolean {
		return (this.previousAttributeOptions?.length || attributeOptions?.length) && attributeOptions.toString() !== this.previousAttributeOptions.toString();
	}

	private getSignalLink(values): string {
		const params = [];

		values.forEach(value => {
			params.push('attributeOptions[]=' + value);
		});

		return this.loadRespondentsSignalUrl + '&' + params.join('&');
	}

	private createCheckboxes(items): void {
		const $respondentSelection = this.$component.find('[data-respondent-selection]');
		const $respondentList = $respondentSelection.find('[data-respondent-list]');
		const $template = this.$component.find('[data-respondent-checkbox-template]');

		$respondentSelection.find('[data-select-all-checkbox]').hide().find('input').prop('checked', false);
		$respondentList.find('[data-respondent-checkbox]').hide();

		if (Object.keys(items).length > 0) {
			$respondentSelection.find('[data-select-all-checkbox]').show();
		}

		for (let key in items) {
			if (items.hasOwnProperty(key)) {
				let value = items[key];

				const $existingObject = this.$component.find(`.form-check-input[value="${key}"]`).first().closest('div');
				if ($existingObject.length) {
					$existingObject.show();
				} else {
					const $item = $template.clone();
					$item.find('[data-input]').val(key);
					$item.find('[data-label]').append(value);
					$item.removeAttr('data-respondent-checkbox-template').show().appendTo($respondentList);
				}
			}
		}
        this.checkCheckboxesBySelectedRespondents();
	}

	private getFilteredRespondentsSelectionIds(): string[] {
		const respondents = [];

		this.$component.find('[data-respondent-list] input:checked').each((i, input: HTMLInputElement) => {
			if (!respondents.includes(input.value)) {
				respondents.push(input.value)
			}
		});

		return respondents;
	}


	private removeOrAddRespondents(): void {
		this.$component.find('[data-selected-respondents] input').prop('checked', false).parent().hide();

		const respondentIds = this.getFilteredRespondentsSelectionIds();
		const $unselectedRespondents = this.$component.find('[data-selected-respondents] input:not(:checked)');

		$unselectedRespondents.each((i, input: HTMLInputElement) => {
			if (respondentIds.indexOf(input.value) !== -1) {
				$(input).prop('checked', true).parent().show();
			}
		});

		this.processRespondentCounter();
		this.resetSeparators();
        this.updateNoRespondentsMessage();
	}

	private addRespondents(ids = null): void {
		const respondentIds = ids ? ids : this.getFilteredRespondentsSelectionIds();
		const $unselectedRespondents = this.$component.find('[data-selected-respondents] input:not(:checked)');

		$unselectedRespondents.each((i, input: HTMLInputElement) => {
			if (respondentIds.includes(input.value)) {
				$(input).prop('checked', true).parent().show();
			}
		});
		this.checkCheckboxesBySelectedRespondents();
		this.processRespondentCounter()
		this.resetSeparators();
        this.updateNoRespondentsMessage();
	}

	private addRespondentsByIds(): void {
		const $textarea = this.$component.find('[data-respondent-ids-textarea]');
		const value = <string>$textarea.val() || '';
		this.addRespondents(value.match(/[^\r\n\s,;a-z\-]+/g));
		$textarea.val('');

		const $parentModal = this.$component.find('#addById');
		$parentModal.modal('hide');
		this.updateNoRespondentsMessage();
	}

	private processRespondentCounter(): void {
		const h4Element = this.$component.find('[data-selected-respondents-title]')[0];
		let counter = this.getFilteredRespondentsSelectionIds().length;
		if (h4Element) {
			const h4ElementTitle = h4Element.getAttribute('data-selected-respondents-title');
			if (h4ElementTitle) {
				if (counter > 0) {
					h4Element.textContent = h4ElementTitle + ' (' + counter + ')' + ':';
				} else {
					h4Element.textContent = h4ElementTitle + ':';
				}
			}
		}
	}

	private removeRespondents(removeAll = false): void {
		const respondentIds = this.getFilteredRespondentsSelectionIds();
		const $selectedRespondents = this.$component.find('[data-selected-respondents] input:checked')

		$selectedRespondents.each((i, input: HTMLInputElement) => {
			if (removeAll || respondentIds.includes(input.value)) {
				$(input).prop('checked', false).parent().hide();
			}
		});

		if (removeAll) {
			this.$component.find('[data-respondent-checkbox] input').prop('checked', false);
			this.$component.find('[data-select-all-checkbox] input').prop('checked', false);
			this.$component.find('[data-select-new-checkbox] input').prop('checked', false);
		}
		this.checkCheckboxesBySelectedRespondents();
		this.processRespondentCounter()
		this.resetSeparators();
		this.updateNoRespondentsMessage();
	}

	private removeAllRespondents(): void {
		this.removeRespondents(true);
	}

	private resetSeparators(): void {
		const $respondents = this.$component.find('[data-selected-respondents] label');

		$respondents.removeClass('last-selected');
		$respondents.find('input:checked').last().parent().addClass('last-selected');
	}

	private areAllNewUsersChecked(): boolean {
		const ids = this.$component.find('[data-select-new-checkbox]').data('ids')?.toString()?.split(",") || [];
		const checked = ids.filter((id) => $('[data-respondent-checkbox] input[value="'+id+'"]').is(':checked'));
		return checked.length === ids.length;
	}

	private selectNewUsers(e): void {
        // check / uncheck
        $(e).children('input').prop('checked', !$(e).children('input').is(':checked'));
        let ids = $(e).data('ids').toString().split(',');
        const check = $(e).children('input').is(':checked');
        ids.forEach((id) => {
            $('[data-respondent-checkbox] input[value="' + id + '"]').prop('checked', check);
        });
	}

	private updateNoRespondentsMessage(): void {
	    const $message = this.$component.find('[data-no-respondents-selected-message]')
	    if (this.$component.find('[data-selected-respondents] input:checked').length) {
            $message.hide()
        } else {
            $message.removeClass('info-box').addClass('error-box');
	        $message.show();
        }

    }

	private checkCheckboxesBySelectedRespondents() {
		this.$component.find('[data-respondent-checkbox] input').prop('checked', false);
		const respondentIds = this.getFilteredRespondentsSelectionIds();
		const $unselectedRespondents = this.$component.find('[data-respondent-list] input');

		$unselectedRespondents.each((i, input: HTMLInputElement) => {
			if (respondentIds.includes(input.value)) {
				$(input).prop('checked', true).parent().show();
			}
		});
	}

	private isSelectedOption() {
		let selected = false;
		this.$component.find('select[data-attribute-option-select]').each((i, input: HTMLInputElement): void => {
			if (input.value !== '') {
				selected = true;
			}
		});

		return selected;
	}
}
