import BaseComponent from '../../BaseComponent';

export default class SearchInput extends BaseComponent {
    private $searchInput: JQuery<HTMLInputElement>

    public init (): void {
        this.$searchInput = <JQuery<HTMLInputElement>> this.$component;

        this.context.naja.addEventListener('complete', () => {
            this.searchContent();
        });

        this.$searchInput.on('input', () => this.searchContent());

        this.$searchInput.on('change', e => e.stopPropagation());
    }

    private searchContent () {
        const searchedValue = String(this.$searchInput.val() ?? '');
        const $items = <JQuery<HTMLElement>> $('[data-search="container"]').children();

        // @ts-ignore
        $items.each((index, element: HTMLElement) => {
            const elementKeyWords = element.getAttribute('data-search-keywords');

            // DONT HIDE ELEMENTS WITHOUT KEYWORDS
            if (!searchedValue || !elementKeyWords) {
                element.hidden = false;
                return true;
            }

            // HIDE OR SHOW ELEMENT
            element.hidden = this.shouldBeHidden(searchedValue, elementKeyWords);
        });
    }

    private shouldBeHidden ($searchedValue, elementKeyWords) {
        $searchedValue = this.removeDiacritics($searchedValue);
        elementKeyWords = this.removeDiacritics(elementKeyWords);

        return !elementKeyWords.includes($searchedValue);
    }

    private removeDiacritics (str: string): string {
        if (str.normalize) {
            str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        }
        return str.replace(/ +/, ' ').trim().toLocaleLowerCase();
    }
}
